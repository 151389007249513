<template>
    <div class="newsticker-container">
        <div class="news-heading hide-on-mobile">
            <img alt="baketrans" :src="`/assets/images/icons/icon-speaker.svg`" class="me-1 hide-on-mobile">{{ (lang
                ==
                'en' ? 'News' : 'Berita') }}
        </div>
        <div class="newsticker">
            <div class="news">
                <template v-if="newsTicker.length > 0">
                    <template v-for="(newsItem, index) in newsTicker" :key="index">
                        <a href="javascript:;" @click.prevent="openLink(newsItem?.url, newsItem?.target)"
                            aria-label="news" :class="['news-single', 'truncate', { active: index === currentActive }]"
                            @mouseover="stopNews = true" @mouseleave="stopNews = false">
                            &bull; {{ newsItem?.title }}
                        </a>
                    </template>
                </template>
            </div>
            <div class="pull-right">
                <a href="javascript:;" @click.prevent="prevNews" class="pe-1">
                    <i class="bi bi-arrow-left-circle"></i>
                </a>
                <a href="javascript:;" @click.prevent="nextNews">
                    <i class="bi bi-arrow-right-circle"></i>
                </a>
                <span class="sticker-info">{{ ' (' + (currentActive + 1) + '/' + newsTicker?.length + ')' }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { fstring } from '@/lib/fstring.js'
export default {
    name: 'NewsTicker',
    data() {
        return {
            fstring: fstring,
            lang: fstring.getLang(),
            currentActive: 0,
            duration: 4000,
            intervalId: null,
            stopNews: false
        };
    },
    async mounted() {
        try {
            await this.getNewsTicker()
        } finally {
            this.startTicker();
        }
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
    },
    computed: {
        ...mapState('homePage', {
            newsTicker: (state) => state.newsTicker
        }),
    },
    methods: {
        ...mapActions('homePage', ['getNewsTicker']),
        startTicker() {
            this.intervalId = setInterval(this.changeNews, this.duration);
        },
        changeNews() {
            if (this.newsTicker.length === 0) return;
            if (!this.stopNews) {
                if (this.currentActive >= this.newsTicker.length - 1) {
                    this.currentActive = 0;
                } else {
                    this.currentActive += 1;
                }
            }
        },
        nextNews() {
            if (this.newsTicker.length === 0) return;

            if ((this.currentActive + 1) < this.newsTicker.length) {
                this.currentActive++;
            } else {
                this.currentActive = 0;
            }
        },
        prevNews() {
            if (this.newsTicker.length === 0) return;

            if ((this.currentActive - 1) >= 0) {
                this.currentActive--;
            } else {
                this.currentActive = this.newsTicker.length - 1;
            }
        },
        openLink(url, target) {
            if (!fstring.isExternalUrl(url)) {
                this.$router.replace(url);
            } else {
                if (target == 1 || target == '1') {
                    window.open(url, '_blank');
                } else {
                    window.open(url, '_self');
                }

            }
        }
    },
};
</script>
<style scoped>
.pull-right {
    display: flex;
    justify-content: space-between;
}

.newsticker-container {
    display: flex;
    justify-content: center;
    width: 100%;
    /* background: #0077B6; */
    background: linear-gradient(to bottom, #001165 -20%, #007cbf 60%);
}

.newsticker {
    color: #73c2fb;
    padding: 5px 32px;
    display: flex;
    overflow: hidden;
    width: 100%;
    margin-left: 100px;
    /* Optional: limit the max width */
}

.newsticker::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    /* background: linear-gradient(transparent, #4682B4); */
    /* Adjusted gradient color */
}

/* .newsticker .news-heading {
    font-size: 13px;
    padding-right: 20px;
    margin-right: 10px;
    border-right: 1px solid #fff;
    color: #fff;
    min-width: 90px;
} */

.newsticker .news {
    position: relative;
    width: 100%;
    height: 1.5em;
    /* Set a fixed height to prevent collapse */
    overflow: hidden;
    text-shadow: 1px 1px 2px #1B4089, 1px 1px 10px #d41919;
    letter-spacing: 1.5px;
}

.newsticker .news-single {
    font-size: 1em;
    color: #fff;
    text-decoration: none;
    font-weight: normal;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-8px);
    transition: all 800ms ease;
    pointer-events: none;
}

.newsticker .news-single.active {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}

.newsticker .news-single:hover {
    margin-left: 5px;
    color: #ffc41d;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 95%;
    /* Adjust as needed */
    display: block;
    text-transform: uppercase;
}

.badge-warning {
    background-color: #001461;
    color: #ffffff;
    padding: 0.4em 0.8em;
    font-size: 0.75em;
    font-weight: 700;
    line-height: 9px;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    margin-right: 0.8em;
    border-radius: 50px;
    text-transform: uppercase;
    min-width: 80px;
}

.newsticker-container .news-heading {
    --f: .5em;
    /* control the folded part*/
    --r: .8em;
    /* control the ribbon shape */
    width: 120px;
    height: 37px;
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    position: absolute;
    bottom: 2px;
    text-transform: uppercase;
    left: 0;
    padding-inline: 1em;
    letter-spacing: 1px;
    line-height: 2.2em;
    background: #023E8A;
    border-top: var(--f) solid #0005;
    border-right: var(--r) solid #0000;
    clip-path:
        polygon(calc(100% - var(--r)) 100%, 0 100%, 0 var(--f), calc(100% - var(--r)) var(--f),
            100% calc(50% + var(--f)/2));

}

.newsticker-container .news-heading img {
    width: 25px;
    /* -webkit-filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.80));
    -webkit-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear; */
}

.sticker-info {

    font-size: 10px;
    padding-left: 5px;
    padding-right: 2px;
    line-height: 20px;
}

@media (max-width: 991px) {
    .hide-on-mobile {
        display: none !important;
    }

    .newsticker {
        margin-left: 0;
        /* Optional: limit the max width */
    }


}
</style>
