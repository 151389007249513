<template>
  <div>
    <!-- Overlay -->
    <div class="overlay" v-show="isSearchPopupOpen" @click="toggleSearchPopup"></div>

    <!-- Search Popup -->
    <div class="search-popup" v-show="isSearchPopupOpen">
      <div class="search-header">
        <input
          type="text"
          class="search-input"
          v-model="searchQuery"
          placeholder="Pencarian"
          @input="checkSearchResults"
        />
      </div>

      <!-- Conditional: No Results Found Message -->
      <div v-if="noResults" class="no-results">
        <i class="bi bi-exclamation-circle warning-icon"></i>
        <p class="no-results-message">Hasil pencarian tidak ditemukan</p>
        <p class="no-results-subtext">Coba istilah lain atau periksa kembali pencarian Anda.</p>
      </div>

      <!-- Recent News Section -->
      <div v-else class="recent-news">
        <div
          v-for="(news, index) in filteredNews"
          :key="index"
          class="news-item"
        >
          <i class="bi bi-clock clock-icon"></i>
          <div class="news-info">
            <div class="news-title">{{ news.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isSearchPopupOpen: false,
      searchQuery: '',
      noResults: false,
      recentNews: [
        { title: 'Recent Post 1' },
        { title: 'Recent Post 2' },
        { title: 'Recent Post 3' },
        { title: 'Recent Post 4' },
        { title: 'Recent Post 5' },
      ],
      filteredNews: [],
    };
  },
  methods: {
    toggleSearchPopup() {
      this.isSearchPopupOpen = !this.isSearchPopupOpen;
      if (this.isSearchPopupOpen) {
        this.preventBodyScroll();
      } else {
        this.allowBodyScroll();
      }
    },
    checkSearchResults() {
      this.filteredNews = this.recentNews.filter((news) =>
        news.title.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
      this.noResults = this.filteredNews.length === 0;
    },
    preventBodyScroll() {
      document.body.style.overflow = 'hidden';
    },
    allowBodyScroll() {
      document.body.style.overflow = '';
    },
  },
  beforeDestroy() {
    this.allowBodyScroll();
  },
  mounted() {
    this.filteredNews = this.recentNews;
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
}

.search-popup {
  position: fixed;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 600px;
  max-height: 400px;
  overflow-y: auto;
  z-index: 1000;
}

.search-header {
  padding: 12px;
  background: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  position: sticky;
  top: 0;
  z-index: 10;
}

.search-input {
  width: 100%;
  padding: 12px;
  border: none;
  background: #f0f0f0;
  border-radius: 8px;
  font-size: 14px;
}

.search-input:focus {
  outline: none;
  background: #e0e0e0;
}

.no-results {
  text-align: center;
  padding: 40px 20px;
  color: #666;
}

.warning-icon {
  font-size: 24px;
  color: #ff5f5f;
  margin-bottom: 12px;
}

.no-results-message {
  font-size: 18px;
  font-weight: 500;
}

.no-results-subtext {
  font-size: 14px;
  color: #888;
  margin-top: 8px;
}

.recent-news {
  padding: 12px;
  max-height: 250px;
  overflow-y: auto;
}

.news-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.clock-icon {
  margin-right: 12px;
  font-size: 18px;
  color: #888;
}

.news-info {
  display: flex;
  flex-direction: column;
}

.news-title {
  font-weight: 300;
  font-size: 13px;
  margin: 0;
}
</style>
