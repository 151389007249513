import axiosApi from '@/lib/axios';
import { start, complete } from '../assets/plugins/nanobar/nanobar.js';
const nanoStart = start;
const nanocomplete = complete;

const pejabatPage = {
    namespaced: true,
    strict: false,
    state: {
        pejabat_list: [],
        pejabat_detail: [],
    },
    mutations: {
        SET_PEJABAT_LIST(state, payload) {
            state.pejabat_list = payload
        },
        SET_PEJABAT_DETAIL(state, payload) {
            state.pejabat_detail = payload
        },

    },
    actions: {
        getPejabatList({ commit }, params) {
            //nanoStart();
            return new Promise((resolve, reject) => {
                axiosApi.get('pejabat-page/list')
                    .then((response) => {
                        commit('SET_PEJABAT_LIST', response.data.data)
                        resolve(response.data)
                        //nanocomplete();
                    })
                    .catch((error) => {
                        //nanocomplete();
                        //reject(error)
                    })
            })
        },
        getPejabatDetail({ commit }, params) {
            //nanoStart();
            return new Promise((resolve, reject) => {
                axiosApi.get('pejabat-page/get?slug=' + params)
                    .then((response) => {
                        commit('SET_PEJABAT_DETAIL', response.data.data)
                        resolve(response.data)
                        //nanocomplete();
                    })
                    .catch((error) => {
                        //nanocomplete();
                        //reject(error)
                    })
            })
        },

    }
};

export default pejabatPage