<template>
  <ul class="main-nav">
    <li v-for="item in menu" :key="item.id" :class="(item?.havesubmenu ? 'has-submenu' : '')">
      <router-link v-if="!item?.havesubmenu" :to="(item?.link == 1) ? 'javascript:;' : item?.link"
        :target="item?.target">
        <i :class="item?.addclass + ` hide-on-mobile`"></i>
        {{ item.title }}
      </router-link>
      <a v-else href="javascript:void(0);">
        <i :class="item?.addclass + ` hide-on-mobile`"></i> {{ item?.title }} <i class="fas fa-chevron-down"></i>
      </a>
      <ul v-if="item?.havesubmenu" class="submenu">
        <li v-for="submenu in item?.sub" :key="submenu.id" :class="(submenu?.havesubmenu ? 'has-submenu' : '')">
          <template v-if="submenu?.havesubmenu">
            <a href="javascript:;">
              {{ submenu?.title }}
              <ol v-if="submenu?.havesubmenu" class="submenu2">
                <li v-for="submenu2 in submenu?.sub" :key="submenu2.id">
                  <template v-if="submenu2?.target == '_self' && (submenu2?.url != '' || submenu2?.url != '#')">
                    <router-link v-if="submenu2?.target == '_self' && submenu2?.url != ''" :to="submenu2?.link"
                      :target="submenu?.target">
                      {{ submenu2?.title }}
                    </router-link>
                  </template>
                  <template v-else="submenu2?.target == '_blank' && (submenu2?.url != '' || submenu2?.url != '#')">
                    <a :href="submenu2?.link" :target="submenu2?.target" aria-label="submenu2">
                      {{ submenu2?.title }}
                    </a>
                  </template>
                </li>
              </ol>
            </a>
          </template>
          <template v-else>
            <template v-if="submenu?.target == '_self' && submenu?.url != ''">
              <router-link v-if="submenu?.target == '_self' && (submenu?.url != '' || submenu?.url != '#')"
                :to="submenu?.link" :target="submenu?.target">
                {{ submenu?.title }}
              </router-link>
            </template>
            <template v-else="submenu?.target == '_blank' && submenu?.url != ''">
              <a :href="submenu?.link" :target="submenu?.target" aria-label="submenu">
                {{ submenu?.title }}
              </a>
            </template>
          </template>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>

export default {
  props: ['menu'],
  components: {},
  mounted() {
    if ($(window).width() <= 991) {
      var Sidemenu = function () {
        this.$menuItem = $(".main-nav a");
      };

      function init() {

        $(".main-nav a").on("click", function (e) {
          if ($(this).parent().hasClass("has-submenu")) {
            e.preventDefault();
          }
          let x = e.target;
          // if (e.target.classList.contains("submenu2-header")) {

          // } else {
          if (!$(this).hasClass("submenu")) {
            $("ul", $(this).parents("ul:first")).slideUp(350);
            // $("a", $(this).parents("ul:first")).removeClass("submenu");
            $(this).next("ul").slideDown(350);

            // $(this).addClass("submenu");
          } else if ($(this).hasClass("submenu")) {
            // $(this).removeClass("submenu");
            $(this).next("ul").slideUp(350);

          }
          // }

        });
      }

      setTimeout(() => {
        init();
      }, 3000); // 2000ms = 2 seconds
    }

  },
  computed: {

  },
};
</script>

<style scoped>
.home-icon {
  content: url('/public/assets/images/icons/menu-beranda.svg');
  width: 20px;
  vertical-align: middle;
}

.profile-icon {
  content: url('/public/assets/images/icons/menu-profil.svg');
  width: 20px;
  vertical-align: middle;
}


.public-icon {
  content: url('/public/assets/images/icons/menu-publikasi.svg');
  width: 20px;
  vertical-align: middle;
}

.galeri-icon {
  content: url('/public/assets/images/icons/menu-galeri.svg');
  width: 20px;
  vertical-align: middle;
}

.layanan-icon {
  content: url('/public/assets/images/icons/menu-service.svg');
  width: 20px;
  vertical-align: middle;
}

.ppid-icon {
  content: url('/public/assets/images/icons/menu-ppid.svg');
  width: 20px;
  vertical-align: middle;
}

.kontak-icon {
  content: url('/public/assets/images/icons/menu-kontak.svg');
  width: 20px;
  vertical-align: middle;
}


.header .main-menu-wrapper .main-nav>li .submenu>li .submenu2 {
  display: none;
  position: absolute;
  background-color: #fff;
  list-style-type: none;
  padding: 0;
  margin: 0;
  z-index: 1;
  box-shadow: 0 15px 18px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-bottom: 3px solid #1B408A;
  border-left: 3px solid #F5C11F;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  min-width: 250px;
}

.header .main-menu-wrapper .main-nav>li .submenu>li:hover .submenu2 {
  left: 100%;
  top: -10px;
  margin-top: 10px;
  display: block;
  z-index: 1000;
  position: absolute;
  overflow: hidden;
}

.header .main-menu-wrapper .main-nav>li .submenu>li .submenu2 li a:hover {
  color: #fff;
  background-color: #081265;
}

.header .main-menu-wrapper .main-nav>li a:hover {
  color: #F5C11F;
}

/* CSS to hide the icon on mobile view */
@media (max-width: 991px) {
  .hide-on-mobile {
    display: none;
  }

  .header .main-menu-wrapper .main-nav>li .submenu>li .submenu2 {
    display: none;

  }

  .header .main-menu-wrapper .main-nav>li .submenu>li:hover .submenu2 {

    display: contents;
  }

  .header .main-menu-wrapper .main-nav {
    overflow-y: auto;
    max-height: 600px;
  }
}
</style>