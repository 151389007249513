<template>
    <div class="main-wrapper">
        <div class="page-wrapper">
            <app-bread-crumb :homeText="lang == 'en' ? 'Home' : 'Beranda'" :path="data.link" :homeRoute="data.name"
                :title="data.description" :description="data.description" />
            <div v-html="baseData?.contents"></div>
            <component v-if="contents == ''" :is="currentComponent" :key="$route.params.id" />
        </div>
    </div>
</template>

<script>
import AppBreadCrumb from '@/views/layouts/bread-crumb.vue'

import { defineAsyncComponent, ref, shallowRef, watch, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { mapState, mapActions, useStore } from 'vuex'
import { fstring } from '@/lib/fstring.js'
export default {
    components: {
        AppBreadCrumb
    },
    data() {
        return {
            data: [],
            fstring: fstring,
            lang: fstring.getLang(),
        }
    },
    setup() {
        const route = useRoute();
        const reroute = useRouter();
        const store = useStore();

        const currentComponent = shallowRef(null);
        const param = ref(route.params.id); // Use route params in your component
        let contents = "";


        //const { actionOne } = mapActions(['getPageContent']);

        // Bind actions to the store context
        const actions = {
            actionOne: (...args) => store.dispatch('staticPage/getPageContent', ...args),
        };
        const baseData = computed(() => store.state.staticPage.pages);


        let data0 = {
            name: "/",
            title: "Badan Kebijakan Transportasi",
            description: "",
            link: [
                {
                    title: "Badan Kebijakan Transportasi",
                    path: "/profiles/tugas-fungsi",
                    description: ""
                }
            ]
        };

        const data = ref(data0);

        const loadComponent = async (componentName) => {
            try {
                await actions.actionOne([route.params.id]);

                let isloaded = false;
                currentComponent.value = null;
                if (baseData.value?.contents == "" || baseData.value?.contents == null) {
                    if (baseData.value?.component) {
                        currentComponent.value = await defineAsyncComponent(() =>
                            import('@/views/modules/content/components/' + baseData.value?.component + '.vue')
                        );
                    } else {
                        reroute.push({ name: 'error-404' });
                    }
                    isloaded = true;
                } else {

                    contents = baseData.value?.contents;
                }


                data0 = {
                    name: "/",
                    title: baseData.value?.title,
                    description: baseData.value?.description ?? baseData.value?.title,
                    link: [
                        {
                            title: baseData.value?.title,
                            path: "/profiles/tugas-fungsi",
                            description: baseData.value?.description ?? baseData.value?.title
                        },
                    ]
                };

                data.value = data0;
            } catch (error) {

                reroute.push({ name: 'error-404' });
            }
        };
        watch(() => route.params.id, (newId) => {
            param.value = newId;
            loadComponent(newId);

        });

        onMounted(() => {
            loadComponent(route.params.id);

        });

        return {
            currentComponent,
            data,
            contents,
            param,
            baseData
        };
    }
}
</script>
