<template>
  <div class="back-button">
    <a href="javascript:;" @click.prevent="$router.go(-1)" aria-label="back">
      <i class="bi bi-arrow-left-circle"></i>
    </a>
  </div>
  <div class="whatsapp-fab">
    <a href="https://wa.me/6281324092523" target="_blank" rel="noopener noreferrer" aria-label="whatsapp">
      <i class="bi bi-whatsapp"></i>
    </a>
  </div>
</template>

<script>
export default {
  name: "AppWhatsappFab",
};
</script>


<style scoped>
.back-button {
  z-index: 200;
  position: fixed;
  bottom: 20px;
  right: 100px;
  width: 40px;
  height: 40px;
  background-color: #b1b1b1;
  /* background-color: #25D366; */
  /* Custom color */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  opacity: 0.3;

}

.back-button a {
  font-size: 22px;
  color: #fff;
}

.back-button:hover {
  font-size: 22px;
  color: #fff;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  transform: scale(1.1);
  background-color: #001165;
  opacity: 1;
}

.whatsapp-fab {
  z-index: 200;
  position: fixed;
  bottom: 20px;
  right: 55px;
  width: 40px;
  height: 40px;
  background-color: #25D366;
  /* background-color: #25D366; */
  /* Custom color */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.whatsapp-fab:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  transform: scale(1.1);
}

.whatsapp-fab i {
  color: white;
  font-size: 22px;
  /* Adjust icon size */
}

@media (max-width: 768px) {
  .whatsapp-fab {
    width: 40px;
    height: 40px;
  }

  .whatsapp-fab i {
    font-size: 22px;
  }
}
</style>
