import axiosApi from '@/lib/axios';

const homePage = {
    namespaced: true,
    strict: false,
    state: {
        home: [],
        banners: [],
        newsTicker: [],
        latestNews: [],
        pojokKebijakan: [],
        opiniInsan: [],
        info: []
    },
    mutations: {
        SET_HOME(state, payload) {
            state.home = payload
        },
        SET_HOME_INFO(state, payload) {
            state.info = payload
        },
        SET_BANNERS(state, payload) {
            state.banners = payload
        },
        SET_NEWS_TICKER(state, payload) {
            state.newsTicker = payload
        },
        SET_HOME_LATESTNEWS(state, payload) {
            state.latestNews = payload
        },
        SET_HOME_POJOK_KEBIJAKAN(state, payload) {
            state.pojokKebijakan = payload
        },
        SET_HOME_OPIN_INSAN(state, payload) {
            state.opiniInsan = payload
        }
    },
    actions: {
        getBanners({ commit }) {
            return new Promise((resolve, reject) => {
                return axiosApi.get('home-page/banner')
                    .then((response) => {
                        commit('SET_BANNERS', response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        //reject(error)
                    })
            })
        },
        getNewsTicker({ commit }) {
            return new Promise((resolve, reject) => {
                return axiosApi.get('home-page/news-ticker')
                    .then((response) => {
                        commit('SET_NEWS_TICKER', response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        //reject(error)
                    })
            })
        },
        getLatestNews({ commit }) {
            return new Promise((resolve, reject) => {
                return axiosApi.get('home-page/latest-news')
                    .then((response) => {
                        commit('SET_HOME_LATESTNEWS', response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        //reject(error)
                    })
            })
        },
        getLatestPojokKebijakan({ commit }) {
            return new Promise((resolve, reject) => {
                return axiosApi.get('home-page/latest-pojok-kebijakan')
                    .then((response) => {
                        commit('SET_HOME_POJOK_KEBIJAKAN', response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        //reject(error)
                    })
            })
        },
        getLatestOpiniInsan({ commit }) {
            return new Promise((resolve, reject) => {
                return axiosApi.get('home-page/latest-opini-insan')
                    .then((response) => {
                        commit('SET_HOME_OPIN_INSAN', response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        //reject(error)
                    })
            })
        },

        getGlobalInfo({ commit }) {
            return new Promise((resolve, reject) => {
                return axiosApi.get('home-page/info')
                    .then((response) => {
                        commit('SET_HOME_INFO', response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        //reject(error)
                    })
            })
        },
        getHomePages({ commit }) {
            return new Promise((resolve, reject) => {
                return axiosApi.get('home-page')
                    .then((response) => {
                        commit('SET_HOME', response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        //reject(error)
                    })
            })
        }
    }
};

export default homePage