import axiosApi from '@/lib/axios';
import { start, complete } from '../assets/plugins/nanobar/nanobar.js';
const nanoStart = start;
const nanocomplete = complete;

const staticPage = {
    namespaced: true,
    strict: false,
    state: {
        pages: [],
    },
    mutations: {
        SET_PAGES(state, payload) {
            state.pages = payload
        }

    },
    actions: {
        getPageContent({ commit }, params) {
            //nanoStart();
            return new Promise((resolve, reject) => {
                axiosApi.post('static-page/get', { slug: params[0] })
                    .then((response) => {
                        commit('SET_PAGES', response.data.data)
                        resolve(response.data)
                        //nanocomplete();
                    })
                    .catch((error) => {
                        //nanocomplete();
                        //reject(error)
                    })
            })
        },
    }
};

export default staticPage