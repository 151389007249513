<template v-if="splasData?.length>0">
    <Modal v-if="isModalVisible" :isVisible="isModalVisible" @close="hideModal">
        <template v-slot>
            <div class="owl-carousel">
                <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
                    <Slide v-for="item in  splashData " :key="item.id">

                        <div class="splash-image">
                            <img :src="fstring.getAssetpath(item.image)" alt="baketrans">
                        </div>

                        <button class="btn btn-sm btn-open-spalash" @click="openLink(item)" aria-label="close">
                            {{ lang == 'en' ? 'More' : 'Selengkapnya' }} <i
                                class="bi bi-box-arrow-up-right ps-1 pe-1 "></i>
                        </button>
                    </Slide>
                    <template #addons>
                        <Navigation />
                        <Pagination />
                    </template>
                </Carousel>
                <button class="btn btn-sm btn-primary1 btn-close-spalash" @click="hideModal" aria-label="close">
                    <i class="bi bi-x-circle"></i>
                </button>
            </div>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/component/Modal.vue';
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import { fstring } from '@/lib/fstring.js'
export default {
    props: ['splashData'],
    components: {
        Modal,
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    data() {
        return {
            fstring: fstring,
            lang: fstring.getLang(),
            isModalVisible: false,
            settings: {
                itemsToShow: 1,
                snapAlign: "center",
                wrapAround: true,
                arrows: true,
                autoplay: 3000,
                transition: 500,
                pauseAutoplayOnHover: true
            },
            breakpoints: {
                0: {
                    itemsToShow: 1,
                    itemsToScroll: 1,
                    snapAlign: 'center',
                },
                700: {
                    itemsToShow: 1,
                    snapAlign: "center"
                },
                // 1024 and up
                1024: {
                    itemsToShow: 1,
                    snapAlign: "center"
                }
            },
        };
    },
    methods: {
        showModal() {
            this.isModalVisible = true;
        },
        hideModal() {
            this.isModalVisible = false;
        },
        openLink(item) {
            if (!item?.link || item?.link == null || item?.link == '') {
                this.hideModal();
            } else {
                if (!fstring.isExternalUrl(item?.link)) {
                    this.hideModal();
                    if (item?.target == '0') {
                        this.$router.push(item?.link)
                    } else {
                        window.open(item?.link);
                    }
                } else {
                    this.hideModal();
                    window.open(item?.link);
                }
            }
        }
    },
    mounted() {
        if (this.splashData) {
            if (this.splashData?.length > 0) {
                this.showModal();
            }
        }
    }
};
</script>

<style>
.splash-image {
    width: 100%;
    height: auto;

}

.splash-image img {
    width: 100%;
    height: auto;
    border-radius: 20px;
}


.carousel__prev {
    left: -5px !important;
}

.carousel__next {
    right: -5px !important;
}

.carousel__prev,
.carousel__next {
    border-radius: 100% !important;
    width: 25px !important;
    height: 25px !important;
    background: #fff !important;
    border-color: #fff !important;
}

.carousel__prev:hover,
.carousel__next:hover {
    background: #001165 !important;
    color: #fff !important;
    border-color: #001165 !important;
}

.modal-container {
    background-color: #ffc41d !important;

}

.btn-close-spalash {
    top: 5px;
    right: 10px;
    min-width: 25px;
    position: absolute;
    border-radius: 100% !important;
    text-align: center;
    font-size: 13px !important;
    padding: 2px !important;
    opacity: 0.8 !important;

    color: #fff !important;
}

.btn-close-spalash:hover {
    opacity: 1 !important;
    background: #001165 !important;
    color: #fff !important;
}

.btn-open-spalash {
    bottom: 40px;
    right: 0;
    min-width: 55px;
    position: absolute;
    border-radius: 25px 0 0 25px !important;

    text-align: center;
    font-size: 11px !important;
    padding: 8px !important;
    opacity: 0.7 !important;
    background: #001165 !important;
    color: #fff !important;

}

.btn-open-spalash:hover {
    opacity: 1 !important;
    background: #ffc41d !important;
    color: #fff !important;
    font-weight: 500;
}

@media (max-width: 991.98px) {

    .carousel__prev,
    .carousel__next {

        top: 40% !important;
    }
}
</style>