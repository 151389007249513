<template>
  <div class="custom-loader" v-show="isloading"><img src="/assets/images/logo/logo-tiny.png"></div>
  <div v-show="!isloading">
    <app-header :medsosData="infoData" />
    <router-view />
    <app-foot :medsosData="infoData" />
    <app-foot-icons />
    <app-scroll />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

import AppHeader from '@/views/layouts/header.vue';
import AppFoot from '@/views/layouts/footer.vue'
import AppScroll from '@/views/layouts/scroll.vue'
import AppFootIcons from '@/views/layouts/footericons.vue';
export default {
  name: 'App',
  components: {
    AppHeader,
    AppFoot,
    AppScroll,
    AppFootIcons
  },
  data() {
    return {
      isloading: true,

    }
  },
  computed: {
    ...mapState('homePage', {
      infoData: (state) => state.info
    })
  },
  methods: {
    ...mapActions('homePage', ['getGlobalInfo'])
  },
  async mounted() {
    this.isloading = true;
    try {
      await this.getGlobalInfo()
    } finally {
      this.isloading = false;
    }
  },
}
</script>


<style>
.custom-loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 8px solid;
  border-color: #001165;
  border-right-color: #ffc41d;
  border-left-color: #ffc41d;
  animation: s2 1s infinite linear;
  top: calc(50% - 100px);
  left: calc(50% - 50px);
  position: absolute;
}


.custom-loader img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  /* Logo size */
  height: 80%;
  transform: translate(-50%, -50%);
  /* Center the logo */
  z-index: 10;
  /* Place the logo above the spinner */
}

@keyframes s2 {
  to {
    transform: rotate(1turn)
  }
}

.loader {
  border: 10px solid #001165;
  border-radius: 100%;
  border-top: 10px solid #ffc41d;
  border-bottom: 10px solid #ffc41d;

  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  position: absolute;

  background-image: url('/public/assets/images/logo/logo-tiny.png');
  background-size: cover;

  top: calc(50% - 100px);
  left: calc(50% - 50px);
}

@media (max-width: 767.98px) {
  .loader {
    left: calc(65% - 116px);
    top: calc(40% - 116px);
  }
}

/* Spin animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>