<template>
  <div class="custom-carousel">
    <template v-if="bannerData">
      <div class="carousel-slide">
        <div v-for="(item, index) in bannerData" :key="index" class="carousel-item"
          v-lazy:background="fstring.getAssetpath(item?.image)">
          <div class="carousel-content" v-if="item?.title">
            <p class="fs-10 p-0 m-0">{{ fstring.formattedDate(item?.published) }}</p>
            <div class="carousel-name">
              {{ fstring.toProperCase(item?.title) }}
            </div>
            <div class="carousel-description">{{ item?.description }}</div>
            <button class="carousel-button" @click="$router.push(item?.link)" aria-label="baketrans">
              {{ (lang == 'en' ? 'See More News' : 'Lihat Selengkapnya') }} <i class="bi bi-arrow-right-circle"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="carousel-controls">
        <button class="carousel-prev" @click="movePrev" aria-label="prev">
          <i class="fa-solid fa-arrow-left"></i>
        </button>
        <button class="carousel-next" @click="moveNext" aria-label="next">
          <i class="fa-solid fa-arrow-right"></i>
        </button>
      </div>
    </template>

    <div class="carousel-timer">
      <svg class="timer-svg" viewBox="0 0 36 36">
        <path class="timer-circle" :style="{ strokeDashoffset: timerOffset }" d="M18 2.0845
             a 15.9155 15.9155 0 0 1 0 31.831
             a 15.9155 15.9155 0 0 1 0 -31.831" />
      </svg>
    </div>
  </div>
</template>
<script>
import { fstring } from '@/lib/fstring.js'
export default {
  props: ['bannerData'],
  data() {
    return {
      fstring: fstring,
      lang: fstring.getLang(),
      slideDuration: 8000,
      autoplayInterval: null,
      timerInterval: null,
      timerProgress: 0,
    };
  },
  computed: {
    timerOffset() {
      const radius = 15.9155;
      const circumference = 2 * Math.PI * radius;
      return circumference - (this.timerProgress / 100) * circumference;
    },
  },
  methods: {
    debounce(fn, delay) {
      let timeoutId;
      return (...args) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
          fn(...args);
        }, delay);
      };
    },
    moveNext() {
      const itemsArray = document.querySelectorAll('.carousel-item');
      document.querySelector('.carousel-slide').appendChild(itemsArray[0]);
      this.resetTimer();
    },
    movePrev() {
      const itemsArray = document.querySelectorAll('.carousel-item');
      document.querySelector('.carousel-slide').prepend(itemsArray[itemsArray.length - 1]);
      this.resetTimer();
    },
    startAutoplay() {
      this.autoplayInterval = setInterval(this.debounce(this.moveNext, 100), this.slideDuration);
      this.startTimer();
    },
    stopAutoplay() {
      clearInterval(this.autoplayInterval);
      clearInterval(this.timerInterval);
    },
    startTimer() {
      this.timerProgress = 0;
      this.timerInterval = setInterval(() => {
        this.timerProgress += 100 / (this.slideDuration / 100);
        if (this.timerProgress >= 100) {
          clearInterval(this.timerInterval);
        }
      }, 100);
    },
    resetTimer() {
      this.stopAutoplay();
      this.timerProgress = 0;
      const radius = 15.9155;
      const circumference = 2 * Math.PI * radius;
      document.querySelector('.timer-circle').style.strokeDashoffset = circumference;
      this.startAutoplay();
      //this.startTimer();
    },
    navigateToDetail(item) {
      this.$router.push({ name: 'pubs-siaranpers-detail', params: { id: item.id } });
    },
  },
  async mounted() {
    this.startAutoplay();
  },
  beforeUnmount() {
    this.stopAutoplay();
  },
};
</script>
<style scoped>
.custom-carousel {
  position: relative;
  width: 100%;
  height: 75vh;
  background: #fff;
  box-shadow: 0 30px 70px #fff;
  z-index: 10;
  margin-top: 140px;
}

.carousel-slide {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel-item {
  /* width: 200px;
  height: 300px;
  position: absolute;
  top: 25%;
  border-radius: 20px; */

  background-position: 50% 50% !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat;

  display: inline-block;
  /* transition: 0.5s; */

  justify-content: center;
  align-items: center;
  color: white;

  transition: background-color 0.5s ease-in-out;


}

.carousel-item:nth-child(1),
.carousel-item:nth-child(2) {
  top: 0;
  left: 0;
  transform: translate(0, 0);
  border-radius: 0;
  width: 100%;
  height: 100%;
}

.carousel-item:nth-child(3) {
  left: 95%;
  visibility: hidden;
}

.carousel-item:nth-child(4) {
  left: calc(100% + 220px);
}

.carousel-item:nth-child(5) {
  left: calc(100% + 440px);
}

.carousel-item:nth-child(n + 6) {
  left: calc(100% + 660px);
  opacity: 0;
}

.carousel-content {
  padding: 20px;
  background: rgba(92, 110, 120, 0.362);
  border-radius: 30px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 60%;
  left: 80px;
  width: 50%;
  text-align: left;
  color: #fff;
  transform: translate(0, -50%);
  display: none;
}

.carousel-item:nth-child(2) .carousel-content {
  display: block;
}

.carousel-name {
  font-size: 23px;
  /* text-transform: uppercase; */
  font-weight: 600;
  opacity: 0;
  animation: animate 1s ease-in-out 1 forwards;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  margin: 0;
  text-shadow: 1px 1px 2px #212121, 1px 1px 10px #66666614;
}

.carousel-description {
  margin-top: 10px;
  margin-bottom: 20px;
  opacity: 0;
  animation: animate 1s ease-in-out 0.3s 1 forwards;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow: 1px 1px 2px #212121, 1px 1px 10px #21212167;
  font-size: 16px !important;
}

.carousel-button {
  background: #001165;
  color: #fff;
  padding: 12px 20px;
  border: none;
  cursor: pointer;
  opacity: 0;
  animation: animate 1s ease-in-out 0.6s 1 forwards;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.carousel-button:hover {
  background-color: #ffc41d;
  color: #fff;
  ;
}

@keyframes animate {
  from {
    opacity: 0;
    transform: translate(0, 100px);
    filter: blur(33px);
  }

  to {
    opacity: 1;
    transform: translate(0);
    filter: blur(0);
  }
}

.carousel-controls {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 20px;
}

.carousel-controls button {
  width: 35px;
  height: 35px;
  border-radius: 35px;
  border: none;
  cursor: pointer;
  margin: 0 5px;
  opacity: 0.5;
  /* Set lighter opacity for unhovered state */
  transition: opacity 0.3s, background-image 0.3s, color 0.3s;
  /* Add transition for opacity, background, and color */
}

.carousel-controls button:hover {
  background: #ffffff;
  color: #000;
  opacity: 1;
  /* Set full opacity for hovered state */
}

/* Styling for Timer */
.carousel-timer {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
}

.timer-svg {
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
}

.timer-circle {
  fill: none;
  stroke: #fff;
  stroke-width: 3;
  stroke-dasharray: 100, 100;
  /* transition: stroke-dashoffset 0.1s linear; */
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .custom-carousel {
    height: 500px;
  }

  .carousel-item {
    width: 180px;
    height: 270px;
  }

  .carousel-content {
    left: 80px;
    width: 50%;
  }

  .carousel-name {
    font-size: 23px;
  }

  .carousel-button {
    padding: 8px 16px;
  }

  .carousel-item:nth-child(1),
  .carousel-item:nth-child(2) {
    top: -38px;
  }
}

@media (max-width: 992px) {
  .custom-carousel {
    height: 400px;
  }

  .carousel-item {
    width: 160px;
    height: 240px;
  }

  .carousel-content {
    left: 60px;
    width: 60%;
    top: 50%;
  }

  .carousel-name {
    font-size: 25px;
  }

  .carousel-button {
    padding: 6px 12px;
  }

  .carousel-item:nth-child(1),
  .carousel-item:nth-child(2) {
    top: -38px;
  }
}

@media (max-width: 768px) {
  .custom-carousel {
    height: 380px;
  }

  .carousel-item {
    width: 140px;
    height: 210px;
  }

  .carousel-content {
    left: 40px;
    width: 70%;
    top: 200px;
  }

  .carousel-name {
    font-size: 25px;
  }

  .carousel-button {
    padding: 5px 10px;
  }

  .carousel-item:nth-child(1),
  .carousel-item:nth-child(2) {
    top: -38px;
  }
}

@media (max-width: 576px) {
  .custom-carousel {
    height: 350px;
  }

  .carousel-item {
    width: 120px;
    height: 180px;
  }

  .carousel-content {
    left: 20px;
    width: 90%;
    top: 180px;
  }

  .carousel-name {
    font-size: 20px;
  }

  .carousel-button {
    padding: 4px 8px;
  }

  .carousel-item:nth-child(1),
  .carousel-item:nth-child(2) {
    top: -38px;
  }

}
</style>