<template>
  <div class="bloglistleft-widget blog-listview">
    <div class="row">
      <div class="col-lg-8">
        <h3 class="p-0 m-0 text-muted font-weight-normal pb-3">
          <i class="bi bi-briefcase"></i> <span class="text-capitalize">{{
            (options?.sCategory == undefined || options?.sCategory == '' ? 'Directory' : options?.sCategory) }}</span>
        </h3>
      </div>
      <div class="col-lg-4">
        <div class="input-group">
          <input type="text" class="form-control search-input" v-model="options.sQuery"
            :placeholder="(lang == 'en' ? `Enter keywords...` : `Cari informasi`) + (totalRecord > 0 ? ' ( ' + (lang == 'en' ? 'Found ' : 'Ditemukan ') + totalRecord + ' item)' : '')"
            maxlength="100" aria-label="search" aria-describedby="button-search" :disabled="options.isloading"
            @keypress="callcheckEnter">

          <button class="btn btn-outline-secondary" type="button" id="button-search" @click="callsearchData"
            :disabled="options.isloading" aria-label="search">
            <i v-if="options.isloading" class="spinner-border spinner-border-sm align-middle"></i>
            <i v-else class="bi bi-search"></i>
          </button>
        </div>
      </div>
    </div>
    <template v-if="baseData.news">
      <div v-show="totalRecord < 1">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="banner-info">
                <h2 class="p-0 m-0">{{ lang == 'en' ? 'No data' : 'Hasil Pencarian Tidak Ditemukan' }}</h2>
                <p class="p-0">{{ lang == 'en' ? 'Keywords' : 'Kata kunci' }} <strong>'{{ sQuerySubmit }}'</strong>
                  {{ lang == 'en' ? `not found in our system, please try with other keywords!` : `tidak ditemukan dalam
                  sistem kami, silahkan coba dengan kata kunci lain !`}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row ">

        <div class="col-lg-3 col-md-6 d-lg-flex pb-2" v-for="item in baseData?.news?.data" :key="item.id">
          <div class="blog grid-blog">
            <div class="blog-content ">
              <div class="between">
                <div class="d-flex related-post-meta">
                  <div class="media-body text-start">
                    <div class="">

                    </div>
                  </div>
                </div>
                <span> <i class="feather-eye"></i> {{ item?.views }} </span>
              </div>
            </div>
            <div class="blog-image p-2 ">
              <router-link :to="`/directory/${options?.slug}/${item?.slug}`">
                <img class="img-strecth" v-lazy="fstring.getAssetpath(item?.thumbnail)" width="100%" height="400"
                  alt="baketrans" />
              </router-link>
            </div>
            <div class="blog-content">
              <h3 class="blog-title">
                <div class="amount-details">
                  <div class="amount">
                    <span class="validrate ellipsis4">
                      <router-link :to="`/directory/${options?.slug}/${item?.slug}`">
                        {{ item?.title }}
                      </router-link>
                    </span>
                  </div>
                </div>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { fstring } from '@/lib/fstring.js'
export default {
  props: {
    options: {
      type: Object,
      default: null
    },
    baseData: {
      type: Object,
      default: null
    },
    totalRecord: {
      type: Number,
      default: 0
    },
    sCategory: {
      type: String,
      default: ''
    },
    sQuerySubmit: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      fstring: fstring,
      lang: fstring.getLang(),
    }
  },
  computed: {
  },
  methods: {
    callshowImage(url) {
      this.$emit('showImage', url);
    },
    callcheckEnter(event) {
      this.$emit('checkEnter', event);
    },
    async callsearchData() {
      await this.$emit('searchData');
    },
    async callchangeCategories(value, index) {
      await this.$emit('changeCategories', value, index);
    }
  },
  async mounted() {
    try {

    } finally {

    }
  },
}

</script>

<style scoped>
.search-input {
  background-color: #ffffffff;
  border: 1px solid rgb(247, 247, 247);
  border-radius: calc(0.5*48px);
  color: #000000ff;
  font-family: inherit;
  font-size: inherit;
  height: 100%;
  outline: 0;
  padding-inline-end: calc(20px);
  padding-inline-start: calc(20px);
  position: relative;
  width: 100%;
  margin-bottom: 30px;

}

.blog-image {
  width: 100%;
  height: auto;

}

.blog-image:hover img {
  transform: none;
}

.blog-image img {
  border: 2px solid #eeeff0;
  border-radius: 10px;
}

.grid-blog .blog-title a {
  font-size: 14px;
  font-weight: 500;
  line-height: 1 !important;
}

.grid-blog .entry-meta li {
  margin: 0 0 15px;
  font-size: 10px;
  color: #9c9c9c;
}

.blog-listview .card .blog-widget {
  width: 108%;
}

@media (max-width: 767.98px) {
  .blog-listview .card .blog-widget {
    width: 100%;
  }
}

.ellipsis4 {
  -webkit-line-clamp: 4 !important;
  display: -webkit-box;
  line-height: 20px !important;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.icon32x32 {
  height: 32px;
  width: 32px;
}

.input-group .btn {
  position: static;
  z-index: 2;
  height: 46px;
  border: 1px solid #f5f5f5;
  right: 40px;
  border-radius: 52px;
}

.between {
  display: flex;
  justify-content: space-between;
}

.blog-content {
  padding: 8px 8px 0px 8px;
}
</style>