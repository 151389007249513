<template>
	<div class="mainwrapper error-page">
		<div class="content">
			<div class="container">
				<div class="row top">
					<div class="col-lg-6 mx-auto">
						<div class="error-wrap">

							<div class="error-img">
								<img class="img-fluid" src="@/assets/img/error/505error.jpg" alt="img">
							</div>
							<h3>Something went wrong</h3>
							<router-link to="/" class="btn btn-primary rounded-pill">Back to Home</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style>
.top {
	padding-top: 240px;
}
</style>