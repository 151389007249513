import axiosApi from '@/lib/axios';
import { start, complete } from '../assets/plugins/nanobar/nanobar.js';
const nanoStart = start;
const nanocomplete = complete;

const galeriPage = {
    namespaced: true,
    strict: false,
    state: {
        galeri_fotos: [],
        galeri_videos: [],
    },
    mutations: {
        SET_GALERI_FOTOS(state, payload) {
            state.galeri_fotos = payload
        },
        SET_GALERI_VIDEOS(state, payload) {
            state.galeri_videos = payload
        },

    },
    actions: {
        getGaleriFoto({ commit }, params) {
            //nanoStart();
            return new Promise((resolve, reject) => {
                let pagex = Number(params[0]);
                if (isNaN(pagex) && !isFinite(pagex)) {
                    pagex = 1;
                }
                if (params[2] == undefined || params[2] == null || params[2] == '') {
                    params[2] = '';
                }
                axiosApi.get('galeri-page/foto?page=' + pagex + '&q=' + params[1] + '&c=' + params[2])
                    .then((response) => {
                        commit('SET_GALERI_FOTOS', response.data.data)
                        resolve(response.data)
                        //nanocomplete();
                    })
                    .catch((error) => {
                        //nanocomplete();
                        //reject(error)
                    })
            })
        },
        getGaleriVideo({ commit }, params) {
            //nanoStart();
            return new Promise((resolve, reject) => {
                let pagex = Number(params[0]);
                if (isNaN(pagex) && !isFinite(pagex)) {
                    pagex = 1;
                }
                if (params[2] == undefined || params[2] == null || params[2] == '') {
                    params[2] = '';
                }
                axiosApi.get('galeri-page/video?page=' + pagex + '&q=' + params[1] + '&c=' + params[2])
                    .then((response) => {
                        commit('SET_GALERI_VIDEOS', response.data.data)
                        resolve(response.data)
                        //nanocomplete();
                    })
                    .catch((error) => {
                        //nanocomplete();
                        //reject(error)
                    })
            })
        },

    }
};

export default galeriPage