<template>
  <div class="main-wrapper">
    <div class="page-wrapper">
      <app-bread-crumb :homeText="lang == 'en' ? 'Home' : 'Beranda'" :path="data.link" :homeRoute="data.name"
        :title="content.category" :description="data.description" />
      <section class="berita-single-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-9">
              <div class="card">
                <div class="card-body">
                  <!-- Meta Information -->
                  <div class="blog-meta align-items-center justify-content-between">
                    <div class="meta-info">
                      <div class="row">
                        <div class="col-lg-9 d-flex">
                          <div class="media-body text-start">
                            <div>
                              <div class="user-name"><strong>{{ content.category }}</strong></div>
                              <div class="user-last-chat">{{ fstring.timeAgo(content?.published) }}</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 text-end">
                          <div class="last-chat-time block text-uppercase">
                            <i class="fas fa-user"></i> {{ content?.author }}
                          </div>
                          <div class="last-chat-time block"><i class="fas fa-eye"></i> {{ content?.views }}x {{ lang ==
        'en' ? 'Views' : 'Dilihat' }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Blog Image -->
                  <div class="blog-post-image-container" v-if="content?.image !== 'null'">
                    <a @click.prevent="showImage(fstring.getAssetpath(content?.image))" aria-label="baketrans">
                      <img class="img-strecth blog-post-image" v-lazy="fstring.getAssetpath(content?.image)"
                        alt="baketrans" />
                    </a>

                  </div>
                  <!-- Blog Content -->
                  <div class="blogdetail-content p-2">

                    <h2 class="mb-4 mt-4"><img src="@/assets/img/quote-after.png" width="20" alt="baketrans"> {{
        content?.title }}</h2>
                    <p class="text-center p-0 m-0 writeby"><span class="fw-bold">{{ content?.writeby }}</span></p>
                    <p class="text-center text-muted fs-12 pb-4">{{ formattedDate(content?.published) }}</p>
                    <div class="container">
                      <div class="news-content" v-html="content?.html"></div>
                      <p v-if="content?.source != ''" class="pt-4 fs-11">
                        <span class="fw-bold">{{ lang == 'en' ? 'Source' : 'Sumber' }} : </span>
                        {{ content?.source }}
                      </p>
                    </div>
                    <div class="card p-2 m-2" v-for="(row, index) in content?.download">
                      <div class="text-md-end">
                        <span class="me-4">{{ row?.title }}</span>
                        <a :href="fstring.getAssetpath(row?.link_file)" target="_blank" class="btn btn-view fs-12"
                          aria-label="download"> <i class="fas fa-download me-2"></i>
                          Download</a>
                      </div>
                    </div>
                  </div>
                  <div class="share-postsection">

                    <div class="sharelink">
                      <a :href="`https://www.facebook.com/sharer.php?u=https://baketrans.kemenhub.go.id/berita/` + content?.slug"
                        target="_blank" class="share-img facebook-color" aria-label="facebook"><i
                          class="bi bi-facebook"></i>
                      </a>
                      <a :href="`https://twitter.com/intent/tweet?text=https://baketrans.kemenhub.go.id/berita/` + content?.slug"
                        target="_blank" class="share-img twiter-color" aria-label="twitter"><i
                          class="bi bi-twitter-x"></i>
                      </a>
                      <a :href="`https://wa.me/?text=https://baketrans.kemenhub.go.id/berita/` + content?.slug"
                        target="_blank" class="share-img whatsapp-color" aria-label="whatsapp"><i
                          class="bi bi-whatsapp"></i>
                      </a>
                      <a :href="`https://t.me/share/url?url=https://baketrans.kemenhub.go.id/berita/` + content?.slug"
                        target="_blank" class="share-img telegram-color" aria-label="telegram"><i
                          class="bi bi-telegram"></i>
                      </a>
                      <a :href="`https://www.linkedin.com/sharing/share-offsite/?url=https://baketrans.kemenhub.go.id/berita/` + content?.slug"
                        target="_blank" class="share-img linkedin-color" aria-label="linkedin"><i
                          class="bi bi-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div class="related-posts-section">
                    <h5> {{ lang == 'en' ? 'Related Posts' : 'Postingan Terkait' }}</h5>
                    <div class="row">
                      <div v-for="(post, index) in content?.related" :key="index" class="col-lg-4">
                        <router-link
                          :to="(post?.group == 'news' ? `/berita/${post?.slug}` : `/publikasi/view/${post?.slug}`)"
                          class="related-post-card">
                          <div class="related-post-image-container">
                            <img v-lazy="fstring.getAssetpath(post?.thumbnail)" class="related-post-image img-fluid"
                              alt="baketrans">
                          </div>
                          <div class="related-post-info">
                            <h4>{{ post?.title }}</h4>
                          </div>
                          <div class="d-flex related-post-meta">
                            <div class="media-body text-start">
                              <div class="ps-1">
                                <div class="user-last-chat">{{ formattedDate(post?.published) }}</div>
                              </div>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 theiaStickySidebar1">
              <div class="stickysidebar1">

                <div class="rightsidebar blogsidebar">
                  <div class="card">
                    <h4>
                      <i class="bi bi-briefcase"></i> {{ lang == 'en' ? 'Categories' : 'Kategori' }}
                    </h4>
                    <ul class="blogcategories-list p-0">
                      <li v-for="(row, index) in baseDataKategori">
                        <a class="category-title" href="javascript:;" @click.prevent="changeCategories(row, index)"
                          v-if="!row.uioptions.loading" aria-label="loading">
                          <p class="category-title">{{ row?.title }}</p>
                          <span>{{ row?.total }} </span>
                        </a>
                        <a href="javascript:;" class="category-title" v-if="row.uioptions.loading" aria-label="loading">
                          <p class="category-title" aria-label="Loading...">{{ row?.title }}</p>
                          <i class="spinner-border spinner-border-sm align-middle me-2"></i>
                          <span>{{ row?.total }} </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="rightsidebar blogsidebar mt-4">
                  <div class="card">
                    <h4><i class="feather-tag"></i> {{ lang == 'en' ? 'Latest News' : 'Berita Terbaru' }}</h4>
                    <div class="row">
                      <div v-for="(post, index) in content?.latest" :key="index" class="col-lg-12 pt-3 border-bottom">
                        <router-link :to="`/berita/${post?.slug}`" class="related-post-card">
                          <div class="related-post-image-container">
                            <img v-lazy="fstring.getAssetpath(post?.thumbnail)" class="related-post-image img-fluid"
                              alt="baketrans">
                          </div>
                          <div class="related-post-info">
                            <p class="fs-11">{{ fstring.toProperCase(post?.title) }}</p>
                            <div class="user-last-chat fs-11">{{ formattedDate(post?.published) }}</div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="rightsidebar blogsidebar mt-4">
                  <div class="card tags-widget">
                    <h4>
                      <i class="feather-tag"></i> {{ lang == 'en' ? 'Tags' : 'Tagar' }}
                    </h4>
                    <ul class="tags">
                      <li v-for="(item, index) in content.tag" :key="index">
                        {{ (item == '[' || item == ']' ? '' : '#' +item) }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import AppBreadCrumb from '@/views/layouts/bread-crumb.vue'
import { mapState, mapActions, useStore } from 'vuex'
import Swal from 'sweetalert2'
import { fstring } from '@/lib/fstring.js'

export default {
  components: {
    AppBreadCrumb
  },
  data() {
    return {
      fstring: fstring,
      lang: fstring.getLang(),
      info: {
        id: null
      },
      content: {
        title: "",
        description: "",
        html: "",
        download: "",
        image: "",
        author: "",
        writeby: "",
        source: "",
        published: "",
        views: 0,
        tag: [],
        slug: "",
        related: [],
        latest: [],
        category: ''
      },
      relatedPosts: [],
      placeholderImage: '/assets/images/icons/logo-trans.png',
      data: {
        name: "/berita",
        title: "Berita Seputar Baketrans",
        description: "",
        link: [
          {
            title: "Publikasi",
            path: "/berita",
            description: ""
          },
          {
            title: "",
            path: "",
            description: ""
          }
        ]
      },
    };
  },
  methods: {
    ...mapActions('beritaPage', ['getNewsContent']),
    ...mapActions('kategoriPage', ['getNewsCategory']),
    formattedDate(tdate) {
      if (tdate) {
        const changedDate = tdate.replace(/(..)\/(..)\/(....) (..):(..)/, '$3-$2-$1 $4:$5');
        var date = new Date(changedDate);

        return new Intl.DateTimeFormat('id-ID', {
          timeZone: 'Asia/Jakarta',
          year: 'numeric',
          month: 'long',
          day: '2-digit',
          weekday: 'long'

        }).format(date);
      }
    },
    async changeCategories(value, index) {
      this.$router.push('/berita/kategori/' + value.slug);
    },
    showImage(url) {
      if (url == null) {
        url = this.options.placeholderImage;
      }
      this.$startLoading();
      Swal.fire({
        html: `
        <div v-lazy-container="{ selector: 'img', error: '/assets/images/icons/image-load-failed.png', loading: '/assets/images/icons/image-load.gif' }">
          <img class="img-fluid" src="${url}">
        </div>
        `,
        showConfirmButton: false,
        showCloseButton: true,
        //timer: 2500,
        width: 854,
        heightAuto: false,

        customClass: {
          confirmButton: "btn btn-light-success btn-sm",
          cancelButton: "btn btn-light-danger btn-sm"
        },
        buttonsStyling: false,
        didOpen: () => {
          this.$completeLoading();
        }
      });
    },
  },
  watch: {
    async $route(to, from) {
      this.content.image = this.placeholderImage;
      if (to.params.id !== from.params.id) {
        try {
          this.id = await this.$route.params.id;
          await this.getNewsContent([this.id]);
          await this.getNewsCategory([this.baseData?.news?.group]);
        }
        catch (e) {
          return this.placeholderImage;
        }
        finally {
          if (this.baseData?.news) {
            this.content.title = this.baseData.news?.title;
            this.content.download = this.baseData?.files;
            this.content.description = this.baseData?.news.description;
            this.content.html = this.baseData.news?.content;
            this.content.image = this.baseData.news?.image;
            this.content.author = this.baseData.news?.author;
            this.content.writeby = this.baseData.news?.writeby;
            this.content.source = this.baseData.news?.source_post;
            this.content.published = this.baseData.news?.published;
            this.content.views = this.baseData.news?.views;
            this.content.tag = fstring.JSONstringify(this.baseData.news?.tag);
            this.content.related = this.baseData?.related;
            this.content.slug = this.baseData.news?.slug;
            this.content.latest = this.baseData?.latest;
            this.content.category = this.baseData?.news?.category;
            this.data.link[1].title = this.baseData.news?.category;
            this.fstring.updateKeyword("keywords", this.content.title);
            this.fstring.updateKeyword("description", this.content.title);
          } else {
            this.$router.push({ name: 'error-404' });
          }
        }
      }
    }
  },
  async mounted() {
    try {
      this.id = await this.$route.params.id;
      await this.getNewsContent([this.id]);
      await this.getNewsCategory([this.baseData?.news?.group]);
      // await this.getNewsCategory(["berita"])
    }
    catch (e) {
      return this.placeholderImage;
    }
    finally {
      if (this.baseData?.news) {
        this.content.title = this.baseData.news?.title;
        this.content.download = this.baseData?.files;
        this.content.description = this.baseData?.news.description;
        this.content.html = this.baseData.news?.content;
        this.content.image = this.baseData.news?.image;
        this.content.author = this.baseData.news?.author;
        this.content.writeby = this.baseData.news?.writeby;
        this.content.source = this.baseData.news?.source_post;
        this.content.published = this.baseData.news?.published;
        this.content.views = this.baseData.news?.views;
        this.content.tag = fstring.JSONstringify(this.baseData.news?.tag);
        this.content.related = this.baseData?.related;
        this.content.slug = this.baseData.news?.slug;
        this.content.latest = this.baseData?.latest;
        this.content.category = this.baseData?.news?.category;
        this.data.link[1].title = this.baseData.news?.category;
        document.title = this.content.title;
        this.fstring.updateKeyword("keywords", this.content.title);
        this.fstring.updateKeyword("description", this.content.title);
      } else {
        this.$router.push({ name: 'error-404' });
      }
    }
  },
  computed: {
    ...mapState('beritaPage', {
      baseData: (state) => state.berita_content
    }),
    ...mapState('kategoriPage', {
      baseDataKategori: (state) => state.kategori
    }),

  }
}

</script>

<style scoped>
.berita-single-section {
  padding-top: 30px;
  padding-bottom: 20px;
  background: #f9fafc;
}

@media (max-width: 991px) {
  .berita-single-section {
    background: #f9fafc;
    padding-top: 10px;
  }
}

.blog-title h1 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #333;
}

.blog-meta {
  margin-bottom: 0.5rem;
}

.meta-info span {
  font-size: 0.9rem;
  color: #777;
  margin-right: 1rem;
}

.meta-info i {
  margin-right: 0.3rem;
}

.blog-post-image-container {
  display: block;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.blog-post-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  border: 4px solid #eceff1;
}

@media (max-width: 991px) {
  .blog-post-image {
    width: 100%;
  }
}

.blog-content {
  line-height: 1.7;
  color: #444;
  font-size: 1rem;
}

.blog-content .introduction,
.blog-content .conclusion {
  font-weight: 500;
  margin-bottom: 1.5rem;
}

.blog-content .content-subheading {
  font-size: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
  color: #333;
}

.blog-content p {
  margin-bottom: 1rem;
}

.highlighted-quote {
  font-style: italic;
  margin: 1.5rem 0;
  padding: 1rem;
  border-left: 4px solid #333;
  background-color: #f9f9f9;
  color: #555;
}

.share-postsection {
  margin-top: 2rem;
}

.related-posts-section {
  margin-top: 1rem;
}

.related-posts-section h5 {
  font-size: 1.2rem;
}

.related-post-card {
  display: block;
  text-decoration: none;
  color: inherit;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease;
  margin-bottom: 15px;
}

.related-post-card:hover {
  transform: translateY(-5px);
  /* Add a subtle hover effect */
}

.related-post-image-container {
  width: 100%;
  height: 170px;
  /* Fixed height for consistency */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.related-post-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
  border: 4px solid #eceff1;
}

.related-post-info {
  margin-top: 5px;
  padding: 5px;
  text-align: left;
}

.related-post-info h4 {
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3 !important;
  display: -webkit-box;
  line-height: 1.3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.related-post-meta {
  font-size: 0.7rem;
}

.ellipsis3 {
  -webkit-line-clamp: 3 !important;
  display: -webkit-box;
  line-height: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.ellipsis4 {
  -webkit-line-clamp: 4 !important;
  display: -webkit-box;
  line-height: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  text-align: justify;
}

.rightsidebar .card ul.blogcategories-list li {
  list-style: none;
  position: relative;
}

.rightsidebar .card ul.blogcategories-list li:before {
  content: "\f35a";
  font-family: FontAwesome;
  color: #001165;
  position: absolute;
  margin-right: 10px;
}

.rightsidebar .card ul.blogcategories-list li span {
  background-color: #001165;
  color: #fff;
  border-radius: 10px;
  padding: 2px;
  min-width: 35px;
  max-width: 50px;
  height: 16px;
  text-align: center;

  line-height: 13px;
  font-size: 11px;

}

.category-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 !important;
  margin: 0 !important;
  margin-left: 10px !important;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}

.review-details .rating div {
  font-size: 12px;
  margin-left: 5px;
}

.rating i {
  font-size: 10px;
}

.icon32x32 {
  height: 24px;
  width: 24px;
}


.size-title {
  font-size: 1.475rem !important;
  text-align: center;
  padding-left: 100px !important;
  padding-right: 100px !important;
  color: #111827 !important;
  font-weight: 600 !important;
}

.logo-img {
  width: 35px;
  height: 35px;
}

.blogdetail-content {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.blogdetail-content h2 {
  font-size: 1.6rem;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  text-align: center;
}

.blogdetail-content h5 {
  font-size: 1rem;

  padding-left: 10px;
  padding-right: 10px;
}

.writeby {
  color: #00147a;
}

@media (max-width: 991px) {
  .size-title {
    font-size: 1.175rem !important;
    text-align: center;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .card {
    padding: 10px 10px;
  }

  .card-body {
    padding: 10px 10px;
  }
}

.news-content {
  text-align: justify;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}




@media (max-width: 600px) {
  .blog-post-image {
    height: 250px;
  }

  .related-post-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    border: 4px solid #eceff1;
  }
}

.btn-outline-secondary {
  border-color: #dcdcdc !important;
}


.twiter-color {
  color: #000 !important;
}

.facebook-color {
  color: #1877F2 !important;
}

.instagram-color {
  color: #C13584 !important;
}

.youtube-color {
  color: #FF0000 !important;
}

.whatsapp-color {
  color: #25D366 !important;
}

.telegram-color {
  color: #24A1DE !important;
}

.linkedin-color {
  color: #0a66c2 !important;
}

.share-postsection .sharelink .share-img:hover {
  background: #b6b6b6;
}

.article-content a {
  font-size: 12px !important;
  line-height: 2px !important;
}

.border-bottom {
  border-bottom: 1px dashed #dcdcdc !important;
}
</style>