import { createApp } from 'vue';
import { router } from './router';
import App from "./App.vue";
import { BootstrapVue3, BToastPlugin } from 'bootstrap-vue-3'
// import VueApexCharts from "vue3-apexcharts";
import VueSelect from 'vue3-select2-component'
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import VueEasyLightbox from "vue-easy-lightbox";
import Antd from 'ant-design-vue';
// import AOS from 'aos'
import VueCarousel from 'vue-carousel';
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';

//store
import store from "./store";

// plugins
// import './assets/js/map.js'
// import 'aos/dist/aos.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'ant-design-vue/dist/antd.css';
import './assets/css/feather.css';
import './assets/css/style.css';
import 'bootstrap-icons/font/bootstrap-icons.css';


import VueLazyload from 'vue-lazyload'
import { start, complete } from './assets/plugins/nanobar/nanobar.js';

const app = createApp(App)
start();
app.config.globalProperties.$startLoading = start;
app.config.globalProperties.$completeLoading = complete;
app.config.globalProperties.$isloading = false;
let isLoading = false; // Flag to track loading state

router.beforeEach((to, from, next) => {
  if (!isLoading) {
    isLoading = true; // Set the loading flag
    app.config.globalProperties.$isloading = true;
    start(); // Start NanoBar when navigating to a new route
  }
  next(); // Proceed to the next middleware/route
});

router.afterEach(() => {
  complete(); // Complete NanoBar after the route has loaded
  app.config.globalProperties.$isloading = false;
  isLoading = false; // Reset the loading flag
});

app.use(setupCalendar, {
  popover: {
    placement: 'bottom-start',
    popoverVisibility: 'hover-focus',
  },
});
app.component('VCalendar', Calendar);
app.component('VDatePicker', DatePicker);

app.component('vue-select', VueSelect)
  .use(BootstrapVue3)
  .use(BToastPlugin)
// .use(Antd)
// .use(AOS.init())


app.use(VueLazyload, {
  preLoad: 1.3,
  error: '/assets/images/icons/image-load-failed.png',
  loading: '/assets/images/icons/image-load.gif',
  attempt: 1,
  // the default is ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend']
  listenEvents: ['scroll', 'animationend', 'transitionend']
})


app.use(VueEasyLightbox);
app.use(CoolLightBox);
// app.use(VueApexCharts);
app.use(VueCarousel);
app.use(router);
app.use(store);
app.mount('#app');