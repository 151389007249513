<template>
    <div class="breadcrumb-bar">
        <div class="container">
            <div class="row align-items-center text-center">
                <div class="col-md-12 col-12">
                    <h2 class="breadcrumb-title">{{ currentRouteTitle }}</h2>
                    <nav aria-label="breadcrumb" class="page-breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="homeRoute">{{ homeText }}</router-link>
                            </li>
                            <li v-for="(crumb, index) in breadcrumbs" :key="index" class="breadcrumb-item"
                                :class="{ active: index === breadcrumbs?.length - 1 }" aria-current="page">
                                <router-link v-if="index !== breadcrumbs?.length - 1" :to="crumb?.path">{{ crumb?.title
                                    }}</router-link>
                                <span v-else>{{ crumb.title }}</span>
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        homeText: {
            type: String,
            default: "Beranda",
        },
        homeRoute: {
            type: String,
            default: "/",
        },
        title: "title",
        path: [{}],
    },
    computed: {
        currentRouteTitle() {
            return this.title;
        },
        breadcrumbs() {
            return this.path;
        }

    },
};
</script>

<style>
.breadcrumb-bar {
    background-image: url('@/assets/img/bg/feature-bg.png') !important;
    background-repeat: no-repeat;
}

.breadcrumb-bar .breadcrumb li a {
    color: #001165;
    font-weight: 500;
}

.breadcrumb-bar .breadcrumb li a:hover {
    color: #001165;
    text-decoration: underline;
}
</style>