<template>
  <section class="beranda-pojok-section">
    <div class="container">
      <h2 class="section-title p-0 m-0" v-html="(lang == 'en' ? 'Policy Corner' : 'Pojok Kebijakan')"></h2>
      <p class="section-subtitle"
        v-html="(lang == 'en' ? 'Read Our Policy Publication' : 'Baca Publikasi Kebijakan Kami')"></p>

      <Carousel v-bind="settings" :breakpoints="breakpoints" class="pt-2">
        <Slide v-for="item in pojokData" :key="item.id">
          <router-link :to="`/publikasi/view/${item?.slug}`" class="custom-carousel__item">
            <div class="custom-carousel__content">
              <div class="custom-carousel__image card p-2">
                <img v-lazy="fstring.getAssetpath(item.thumbnail)" alt="Baketrans" class="img-fluid" />
                <div class="overlay">
                  <p>{{ item?.title }}</p>
                </div>
              </div>
            </div>
          </router-link>
        </Slide>
        <template #addons>
          <Navigation />
          <Pagination />
        </template>
      </Carousel>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import { fstring } from '@/lib/fstring.js'

export default defineComponent({
  props: ['pojokData'],
  components: {
    Carousel,
    Navigation,
    Slide,
  },
  data() {
    return {
      lang: fstring.getLang(),
      hideDesc: false,
      fstring: fstring,
      settings: {
        itemsToShow: 4,
        itemsToScroll: 1,
        wrapAround: true,
        arrows: true,
        autoplay: 2000,
        transition: 500,
        pauseAutoplayOnHover: true
      },
      breakpoints: {
        0: {
          itemsToShow: 1,
          itemsToScroll: 1,
          snapAlign: 'center',
        },
        991: {
          itemsToShow: 4,
          itemsToScroll: 1,
          snapAlign: 'start',
        },
      },
      //updateHideDesc: () => {
      //  this.hideDesc = window.innerWidth <= 991;
      // }
    }
  },
  mounted() {
    // this.updateHideDesc();
    // window.addEventListener('resize', this.updateHideDesc());
  },
  methods: {
    // updateHideDesc: () => {
    //   this.hideDesc = window.innerWidth <= 991;
    // }
  }
});
</script>

<style scoped>
.beranda-pojok-section {
  background-color: #E4FBFF;
  /* Set background color */
  padding: 30px 0;
  /* background: url(@/assets/media/images/bg/bg2.jpg) no-repeat;
  background-size: cover; */
}

.section-title {
  font-weight: bold;
  /* Make the text bold */
  text-align: center;
  /* Center align the text */
  font-size: 28px;
  /* Set font size to 36px */
}

.section-subtitle {
  text-align: center;
  font-size: 14px;
  color: #000;
  /* Center align the text */
}

.custom-carousel__item1 {
  display: flex;
  justify-content: center;
  /* Center content horizontally */
  align-items: center;
  /* Center content vertically */
  padding: 10px;
  width: 75%;
  box-sizing: border-box;
  text-decoration: none;
  color: inherit;
}

.custom-carousel__item:hover {
  color: inherit;
  /* Ensure text color does not change on hover */
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.711);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-top: 5px solid #ffc41d;

}

.overlay p {
  display: -webkit-box;
  -webkit-line-clamp: 3 !important;
  line-height: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  min-height: 95px;
  padding: 15px;
}

.custom-carousel__content:hover .overlay {
  opacity: 1;
}

.custom-carousel__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  /* Ensure no overflow beyond container */
}

.custom-carousel__image {
  flex: 0 0 auto;
  width: 90%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-right: 10px;
  margin-left: 10px;
  box-shadow: 3px 3px 3px rgb(73 73 73 / 40%) !important;
}

.desc-card {
  background: #ffffff;
  padding: 25px;
  /* Increased padding for inner space */
  /* box-shadow: rgba(19, 16, 34, 0.05) 0px 4.4px 20px -1px, rgba(19, 16, 34, 0.03) 0px 4px 9px -1px; */
  box-shadow: 0 20px 25px -25px rgba(0, 0, 0, .1), 0 8px 10px -6px rgba(0, 0, 0, .1);
  height: auto;
  width: 100%;
  min-height: 300px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 2rem;
  border: 10px solid #e4fbff76;
  /* border-top: 10px solid #001165; */
  /* border-bottom: 14px solid #001165; */
}

.desc-header {
  margin-bottom: 5px;
  /* Add space below the header */
}

.desc-title {
  font-weight: 500;
  font-size: 1.8em;
  /* Adjust as needed */
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  /* Show up to 2 lines of text */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
}

.desc-date {
  font-size: 0.9em;
  position: absolute;
  /* Position the date absolutely within the card */
  bottom: 25px;
  /* Space from the bottom */
  left: 25px;
  /* Space from the left */
}

.desc-content {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-size: 14px;
  text-align: justify;
  color: #000;
  ;
}

.desc-footer {
  display: flex;
  justify-content: flex-end;
  /* Align footer items to the right */
  align-items: center;
  position: absolute;
  /* Position the footer absolutely within the card */
  bottom: 25px;
  /* Space from the bottom */
  right: 25px;
  /* Space from the right */
}

.desc-views {
  font-size: 0.9em;
}

.fa-calendar,
.fa-eye {
  margin-right: 5px;
}

.vue3-carousel__navigation button {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

/* Media query to adjust layout on smaller screens */
@media (max-width: 991px) {
  .custom-carousel__content {
    flex-direction: column;
    /* Stack image and description vertically */
    align-items: center;
    /* Center items horizontally */
  }

  .custom-carousel__image {
    width: 100%;
    /* Make the image take full width on small screens */
    height: auto;
    /* Adjust height accordingly */
    margin-right: 0;
    /* Remove right margin on small screens */
    margin-bottom: 20px;
    /* Add spacing below the image card */
  }


}
</style>