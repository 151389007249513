import { createRouter, createWebHistory } from 'vue-router';

import pages from '../views/modules/content/content.vue'
import singlepages from '../views/modules/content/page.vue'
import pubs_berita from '../views/modules/content/components/publikasi/berita.vue'
import pubs_berita_detail from '../views/modules/content/components/publikasi/berita-view.vue'
import pubs_directory_index from '../views/modules/content/components/directory/directory-mode-index.vue'
import pubs_directory from '../views/modules/content/components/directory/directory.vue'
import pubs_directory_view from '../views/modules/content/components/directory/directory-view.vue'
import pubs_layanan from '../views/modules/content/components/layanan/layanan.vue'
import pubs_galeri_foto from '../views/modules/content/components/galeri/galeri-foto.vue'
import pubs_galeri_video from '../views/modules/content/components/galeri/galeri-video.vue'
import pubs_contact from '../views/modules/content/components/profiles/contact.vue'
import pubs_beranda from '../views/modules/main/beranda-index.vue';
import pubs_pejabat_view from '../views/modules/content/components/profiles/informasi-pejabat-single.vue'
import pubs_survei from '../views/modules/content/components/layanan/survei-kepuasan-masyarakat.vue'
import Error404 from '../views/layouts/Error404.vue'
import Error500 from '../views/layouts/Error500.vue'

const routes = [
    {
        path: '/',
        name: 'beranda',
        component: pubs_beranda
    },
    {
        title: "Pages",
        path: '/profiles/:id',
        name: 'profiles',
        component: pages
    },
    {
        title: "Single Pages",
        path: '/page/:id',
        name: 'page',
        component: singlepages
    },
    {
        title: "Pages",
        path: '/profiles/pejabat/:id',
        name: 'profiles-pejabat',
        component: pubs_pejabat_view
    },
    {
        title: "Berita",
        path: '/berita',
        name: 'pubs-berita',
        component: pubs_berita
    },
    {
        title: "Berita",
        path: '/berita/kategori/:id',
        name: 'pubs-berita-kategori',
        component: pubs_berita
    },
    {
        title: "Berita View",
        path: '/berita/:id',
        name: 'pubs-berita-detail',
        component: pubs_berita_detail
    },
    {
        title: "Directory Index",
        path: '/directory',
        name: 'pubs-directory-index',
        component: pubs_directory_index
    },
    {
        title: "Directory",
        path: '/directory/:id',
        name: 'pubs-directory',
        component: pubs_directory
    },
    {
        title: "Directory View",
        path: '/directory/:id/:meta',
        name: 'pubs-directory-view',
        component: pubs_directory_view
    },
    {
        title: "Layanan",
        path: '/layanan',
        name: 'pubs-layanan',
        component: pubs_layanan
    },
    {
        title: "Galeri Foto",
        path: '/galeri/foto',
        name: 'pubs-galeri-foto',
        component: pubs_galeri_foto
    },
    {
        title: "Galeri Video",
        path: '/galeri/video',
        name: 'pubs-galeri-video',
        component: pubs_galeri_video
    },
    {
        title: "Kontak",
        path: '/contact',
        name: 'pubs-contact',
        component: pubs_contact
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'error-404',
        component: Error404
    },
    {
        path: '/error-500',
        name: 'error-500',
        component: Error500
    },
    {
        title: "Publikasi",
        path: '/publikasi/:id',
        name: 'pubs-publikasi',
        component: pubs_berita
    },
    {
        title: "Publikasi View",
        path: '/publikasi/view/:id',
        name: 'pubs-publikasi-detail',
        component: pubs_berita_detail
    },
    {
        title: "Survei Kepuasan Masyarakat",
        path: '/survei-kepuasan-masyarakat',
        name: 'pubs-survei',
        component: pubs_survei
    },

]

export const router = createRouter({
    history: createWebHistory('/'),
    linkActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    }
});

router.beforeEach((to, from, next) => {
    try {
        if (to.meta && to.meta.Title) {
            document.title = to.meta.Title;
        } else {
            document.title = 'Badan Kebijakan Transportasi';
        }
        next();
    } catch (error) {
        next({ name: 'error-500' });
    }
});

