import NanoBar from 'nanobar';

let nanobar;
let isLoading = false; // Flag to track loading state

export function start() {
    if (!nanobar) {
        nanobar = new NanoBar();
    }
    if (!isLoading) { // Check if already loading
        isLoading = true; // Set loading state
        nanobar.go(30); // Start loading
    }
}

export function complete() {
    if (nanobar && isLoading) {
        nanobar.go(100); // Complete loading
        setTimeout(() => {
            nanobar.go(0); // Hide the bar after a delay
            isLoading = false; // Reset loading state
        }, 300); // Optional delay before hiding
    }
}