import moment from 'moment'

export const fstring = {
    ifNULL: function (value) {
        let data = "";
        if (value != null) {
            data = value;
        } else {
            data = "";
        }
        return data;
    },
    ToCurrency: function (value) {
        let lang = this.getLang();
        let ret = '';
        if (lang == 'en') {
            let formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                currencyDisplay: "code"
            });
            ret = formatter.format(value).replace("USD", "");
            if (ret == NaN) {
                ret = 0;
            }
        } else {
            let formatter = new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
                currencyDisplay: "code"
            });
            ret = formatter.format(value).replace("IDR", "");
            if (ret == NaN) {
                ret = 0;
            }
        }

        return ret;
    },
    formattedDate(tdate) {
        if (tdate) {
            const changedDate = tdate.replace(/(..)\/(..)\/(....) (..):(..)/, '$3-$2-$1 $4:$5');
            var date = new Date(changedDate);
            const lang = this.getLang();
            if (lang == 'en') {
                return new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit',
                    weekday: 'long'

                }).format(date);
            } else {
                return new Intl.DateTimeFormat('id-ID', {
                    timeZone: 'Asia/Jakarta',
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit',
                    weekday: 'long'

                }).format(date);
            }

        }
    },
    ToDate: function (value) {
        return moment(value).format('DD/MM/YYYY');
    },
    ToDateRange(dates) {
        const start = moment(dates[0]).format('DD/MM/YYYY');
        const end = moment(dates[1]).format('DD/MM/YYYY');
        return `${start} - ${end}`;
    },
    TimeZone: function () {
        return { tz: 'Asia/Jakarta', offset: 7 };
    },
    GetLocalDate: (date) => {
        return moment(date).format('DD/MM/YYYY');
    },
    isInArray(value, array) {
        return array.indexOf(value) > -1;
    },
    NamaBulan: function (value) {
        switch (value) {
            case 1:
            case "1":
            case "01":
                return "Januari";
            case 2:
            case "2":
            case "02":
                return "Februari";
            case 3:
            case "3":
            case "03":
                return "Maret";
            case 4:
            case "4":
            case "04":
                return "April";
            case 5:
            case "5":
            case "05":
                return "Mei";
            case 6:
            case "6":
            case "06":
                return "Juni";
            case 7:
            case "7":
            case "07":
                return "Juli";
            case 8:
            case "8":
            case "08":
                return "Agustus";
            case 9:
            case "9":
            case "09":
                return "September";
            case 10:
            case "10":
                return "Oktober";
            case 11:
            case "11":
                return "November";
            case 12:
            case "12":
                return "Desember";
        }
    },
    toProperCase: function (str) {
        const smallWords = ["and", "the", "in", "of", "for", "on", "to", "a", "an", "with", "dan", "yang", "dalam", "dari", "untuk", "pada", "ke", "dengan", "ikn"];
        return str
            .toLowerCase()
            .split(" ")
            .map((word, index) => {

                return smallWords.includes(word) && index !== 0
                    ? word
                    : word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(" ");
    },
    getLang: function () {
        if (localStorage.getItem('x-lang')) {
            return localStorage.getItem('x-lang').toLowerCase();
        } else {
            return 'id';
        }
    },
    isExternalUrl(url) {
        if (url != null) {
            return url.startsWith('http:') || url.startsWith('https:');
        }
        return true;
    },
    scrollToTop() {
        window.scrollTo({ top: 0, behavior: "smooth" });
    },
    checkImage(src) {
        const img = new Image();
        img.onload = () => {
            return true;
        };
        img.onerror = () => {
            return false;
        };

    },
    JSONstringify(data) {
        try {
            return JSON.parse(data);
        } catch (e) {
            return [];
        }
    },
    getAssetpath(url) {
        if (this.isExternalUrl(url)) {
            return url;
        }
        return process.env.VUE_APP_ASSET_URL + url;
    },
    updateKeyword(metaname, value) {

        let metaDescription = document.querySelector('meta[name="' + metaname + '"]');

        if (!metaDescription) {
            metaDescription = document.createElement('meta');
            metaDescription.name = "'" + metaname + "'";
            document.head.appendChild(metaDescription);
        }

        metaDescription.setAttribute('content', value);
    },
    getViewTerbilang(value) {
        try {
            if (!value) return '0';

            if (value >= 1_000_000) {
                return (value / 1_000_000).toFixed(1).replace('.0', '') + 'jt';
            } else if (value >= 1_000) {
                return (value / 1_000).toFixed(1).replace('.0', '') + 'rb';
            } else {
                return value.toString();
            }
        } catch (e) {
            return "";
        }
    },
    timeAgo(date) {
        try {
            const sekarang = new Date();
            const waktuLalu = new Date(date);
            const selisihDetik = Math.floor((sekarang - waktuLalu) / 1000);

            const satuanWaktu = [
                { satuan: "tahun", detik: 31536000 },
                { satuan: "bulan", detik: 2592000 },
                { satuan: "minggu", detik: 604800 },
                { satuan: "hari", detik: 86400 },
                { satuan: "jam", detik: 3600 },
                { satuan: "menit", detik: 60 },
                { satuan: "detik", detik: 1 },
            ];

            for (const { satuan, detik } of satuanWaktu) {
                const jumlah = Math.floor(selisihDetik / detik);
                if (jumlah > 0) {
                    return `${jumlah} ${satuan} yang lalu`;
                }
            }

            return "Baru saja";
        } catch (e) {
            return "";
        }
    },

}

