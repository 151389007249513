<template>
  <div class="main-wrapper">
    <div class="page-wrapper">
      <app-bread-crumb homeText="Beranda" :path="data.link" :homeRoute="data.name" :title="data.title"
        :description="data.description" />
      <div class="dashboard-content pt-4">
        <div class="container">
          <div class="profile-content">
            <div class="row dashboard-info chat-window">
              <div class="col-lg-3 pb-4">
                <div class="chat-cont-left">
                  <div class="chat-header">
                    <h6><i class="bi bi-tags"></i> {{ lang == 'en' ? 'Video Category' : 'Kategori Video' }}</h6>
                  </div>
                  <div class="chat-users-list">
                    <div class="chat-scroll">
                      <ul class="blogcategories-list p-0">
                        <li v-for="(menuItem, index) in baseDataKategori" :key="index">
                          <a href="javascript:;" @click.prevent="toggleSubmenu(index)" class="menu" aria-label="menu">
                            <span class="me-2"> <i class="bi bi-card-image"></i> </span> {{ menuItem?.title }}

                          </a>
                          <ul v-if="menuItem?.album && menuItem?.isOpen" class="submenu">
                            <li v-for="(submenu, subIndex) in menuItem?.album" :key="subIndex" class="submenu-item">
                              <a href="javascript:;" class="media d-flex" @click.prevent="getAlbumImages(submenu)"
                                aria-label="submenu">
                                <div class="media-img-wrap flex-shrink-0">
                                  <div class="avatar">
                                    <i class="bi bi-images"></i>
                                  </div>
                                </div>
                                <div class="media-body flex-grow-1">
                                  <div>
                                    <div class="user-name">{{ submenu?.title }}</div>
                                    <div class="user-last-chat">{{ submenu?.description }}</div>
                                  </div>
                                  <div>
                                    <div class="badge badge-success rounded-pill">{{ submenu?.total }}</div>
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-9">
                <div class=" gallerypage-info">
                  <h4 class="frame-title">{{ selectedSubcategory.title }}</h4>
                  <p class="frame-desc">{{ selectedSubcategory.description }}</p>
                  <div class="row" v-if="imagesData.length > 0">
                    <div class="col-lg-4 col-md-4 col-sm-4" v-for="(item, index) in imagesData" :key="index"
                      @click.prevent="() => openLightbox(index, item)">
                      <div class="galeries-box">
                        <div class="galeries-img">
                          <a href="#" aria-label="loading">
                            <img class="img-fluid" alt="baketrans"
                              v-lazy="(item?.ytid == '' || item?.ytid == null ? fstring.getAssetpath(item.thumbnail) : `https://img.youtube.com/vi/${item.ytid}/0.jpg`)">
                          </a>
                        </div>
                        <div class="galeries-overlay" v-if="item?.taked || item?.location">
                          <div class="galeries-name">
                            <ul>
                              <li><i class="feather-camera"></i>{{ formattedDate(item?.taked) }}</li>
                              <li><i class="feather-map-pin"></i>{{ item?.location }}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="description">
                        <div>
                          <p> {{ item?.title }} </p>
                          <div class="viewer">
                            <h5>{{ fstring.timeAgo(item?.taked) }} - oleh {{ item?.writeby }}</h5>

                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="row" v-else>
                    <div class="col-lg-4 col-md-4 col-sm-4" v-for="(item, index) in imagesAlbumData" :key="index"
                      @click.prevent="() => getAlbumImages(item, index)">
                      <div class="galeries-box">
                        <div class="galeries-img">
                          <a href="#" aria-label="loading">
                            <img class="img-fluid" alt="baketrans"
                              v-lazy="(item?.latest.ytid == '' || item?.latest?.ytid == null ? fstring.getAssetpath(item?.latest?.thumbnail) : `https://img.youtube.com/vi/${item.latest.ytid}/0.jpg`)">
                          </a>
                        </div>
                        <div class="galeries-overlay">
                          <div class="galeries-name">
                            <ul>
                              <li>{{ item?.title }}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="blog-pagination col-lg-12 pb-4" v-if="imagesData.length > 0">
                    <ul class="pagination justify-content-center pt-4">
                      <li class="page-item previtem" :class="{ disabled: currentPage === 1 }">
                        <a class="page-link" href="#" @click.prevent="prevPage" aria-label="Previous">
                          <i class="fas fa-regular fa-arrow-left"></i>
                        </a>
                      </li>
                      <li class="justify-content-center pagination-center">
                        <div class="pagelink">
                          <ul>
                            <li class="page-item" v-for="page in visiblePages" :key="page"
                              :class="{ active: currentPage === page, 'page-ellipsis': page === '...' }">
                              <a class="page-link" href="#" @click.prevent="goToPage(page)" v-if="page !== '...'"
                                aria-label="Next">
                                {{ page }}</a>
                              <span v-else class="page-link">{{ page }}</span>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="page-item nextlink" :class="{ disabled: currentPage === totalPages }">
                        <a class="page-link" href="#" @click.prevent="nextPage" aria-label="Next">
                          <i class="fas fa-regular fa-arrow-right"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <vue-easy-lightbox :visible="lightboxVisible" :imgs="images" :index="lightboxIndex"
    @hide="closeLightbox"></vue-easy-lightbox>

</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';
import AppBreadCrumb from '@/views/layouts/bread-crumb.vue'
import { mapState, mapActions, useStore } from 'vuex'
import Swal from 'sweetalert2'
import { fstring } from '@/lib/fstring.js'

export default {
  components: {
    VueEasyLightbox,
    AppBreadCrumb
  },
  data() {
    return {
      currentPage: 1,
      isloading: false,
      activeCategory: null,
      fstring: fstring,
      lang: fstring.getLang(),
      selectedSubcategory: {
        title: '',
        description: '',
        slug: ''
      },

      data: {
        name: "/",
        title: "Galeri Video Baketrans",
        description: "",
        link: [
          {
            title: "Galeri",
            path: "/galeri/video",
            description: ""
          },
          {
            title: "Video",
            path: "/galeri/video",
            description: ""
          }
        ]
      },

      images: [],
      imagesData: [],
      imagesAlbum: [],
      imagesAlbumData: [],
      lightboxVisible: false,
      lightboxIndex: 0
    };
  },

  methods: {
    ...mapActions('kategoriPage', ['getVideoCategory']),
    ...mapActions('galeriPage', ['getGaleriVideo']),
    async getAlbumImages(value, index) {
      if (value.album) {
        this.toggleSubmenu(index);
        return false;
      }
      this.isloading = true
      this.currentPage = 1;
      this.selectedSubcategory.title = "";
      this.selectedSubcategory.description = "";
      this.imagesAlbum = [];
      this.imagesAlbumData = [];

      await this.getGaleriVideo([1, "", value.slug]);
      this.images = [];
      this.imagesData = this.baseDataGaleri.galeries.data;
      this.selectedSubcategory.title = value.title;
      this.selectedSubcategory.description = value.description;
      this.selectedSubcategory.slug = value.slug;
      this.baseDataGaleri.galeries.data.forEach(element => {
        this.images.push(this.fstring.getAssetpath(element.image));
      });
      this.isloading = false;
    },
    formattedDate(tdate) {
      if (tdate) {
        const changedDate = tdate.replace(/(..)\/(..)\/(....) (..):(..)/, '$3-$2-$1 $4:$5');
        var date = new Date(changedDate);

        return new Intl.DateTimeFormat('id-ID', {
          timeZone: 'Asia/Jakarta',
          year: 'numeric',
          month: 'long',
          day: '2-digit',
          weekday: 'long'

        }).format(date);
      }
    },
    toggleSubmenu(index) {
      this.isloading = true
      this.imagesAlbum = [];
      this.imagesAlbumData = [];
      this.images = [];
      this.imagesData = [];

      this.baseDataKategori[index].album.forEach((menuItem, i) => {
        if (i !== index) {
          menuItem.isOpen = false;
        }
        this.imagesAlbum.push(this.fstring.getAssetpath(menuItem.image));
        this.imagesAlbumData.push(menuItem);
      });

      // Toggle the clicked submenu
      this.selectedSubcategory.title = this.baseDataKategori[index].title;
      this.selectedSubcategory.description = this.baseDataKategori[index].description;
      this.baseDataKategori[index].isOpen = !this.baseDataKategori[index].isOpen;
      this.isloading = false
    },

    openLightbox(index, item) {
      this.lightboxIndex = index;
      this.lightboxVisible = true;

      if (item.type == '0') {
        this.lightboxVisible = true;
      } else {
        this.lightboxVisible = false;
        let vid = "";
        if (item.ytid != "" && item.ytid != null) {
          vid = `<iframe class="ytvideo"
                        src="https://www.youtube.com/embed/`+ item.ytid + `?autoplay=1&mute=1">
                        </iframe>`;
        } else {
          if (item.video_url != "") {
            vid = `  <video class="ytvideo" controls autoplay>
                                    <source src="`+ this.fstring.getAssetpath(item.video) + `" type="video/mp4">
                                    Your browser does not support the video tag.
                                </video>`;
          }
        }
        Swal.fire({
          html: vid,
          showConfirmButton: false,
          showCloseButton: true,
          //timer: 2500,
          width: 854,
          heightAuto: false,

          customClass: {
            confirmButton: "btn btn-light-success btn-sm",
            cancelButton: "btn btn-light-danger btn-sm"
          },
          buttonsStyling: false
        });
      }

    },
    closeLightbox() {
      this.lightboxVisible = false;
    },
    async goToPage(page) {
      if (page !== '...' && page > 0 && page <= this.totalPages) {
        try {
          this.isloading = true
          this.images = [];
          window.scrollTo({ top: 0, behavior: 'smooth' });

          await this.getGaleriVideo([page, "", this.selectedSubcategory.slug]);

          this.imagesData = this.baseDataGaleri.galeries.data;

          this.baseDataGaleri.galeries.data.forEach(element => {
            this.images.push(this.fstring.getAssetpath(element.image));
          });
        } finally {
          this.currentPage = page;
          this.isloading = false
        }
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.goToPage(this.currentPage);
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.goToPage(this.currentPage);
      }
    },
  },
  // watch: {
  //   async $route(to, from) {
  //     this.isloading = true
  //     try {
  //       if (to.params.id != from.params.id) {
  //         await this.getVideoCategory();
  //       }

  //     } finally {
  //       this.isloading = false;
  //     }
  //   }
  // },
  computed: {
    ...mapState('kategoriPage', {
      baseDataKategori: (state) => state.galeri_video
    }),
    ...mapState('galeriPage', {
      baseDataGaleri: (state) => state.galeri_videos
    }),
    totalPages() {
      return this.baseDataGaleri.galeries.last_page;
    },
    itemsPerPage() {
      try {
        return this.baseDataGaleri.galeries.per_page;
      } catch (e) {

      } finally {
        return 0;
      }
    },
    totalRecord() {
      let total = 0;
      try {
        if (this.baseDataGaleri.galeries) {
          total = this.baseDataGaleri.galeries.total;
        }
      } catch (e) {

      } finally {
        return total;
      }
    },


    // Determine visible pages with ellipses
    visiblePages() {
      const pages = [];
      const maxPagesToShow = 9;//this.itemsPerPage;
      const currentPage = this.baseDataGaleri.galeries.current_page;
      const totalPages = this.totalPages;

      if (totalPages <= maxPagesToShow) {
        for (let i = 1; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        let startPage = Math.max(currentPage - 1, 1);
        let endPage = Math.min(currentPage + 1, totalPages);

        if (currentPage <= 2) {
          endPage = maxPagesToShow;
        } else if (currentPage >= totalPages - 1) {
          startPage = totalPages - (maxPagesToShow - 1);
        }

        for (let i = startPage; i <= endPage; i++) {
          pages.push(i);
        }

        if (startPage > 1) {
          pages.unshift('...');
          pages.unshift(1);
        }

        if (endPage < totalPages) {
          pages.push('...');
          pages.push(totalPages);
        }
      }

      return pages;
    }
  },
  async mounted() {
    this.isloading = true
    try {
      //this.totalPages = 0;
      this.currentPage = 1;
      await this.getVideoCategory();

      this.isloading = true
      this.images = [];
      window.scrollTo({ top: 0, behavior: 'smooth' });

      this.baseDataKategori.forEach((menuItem, i) => {
        this.imagesAlbum.push(this.fstring.getAssetpath(menuItem.image));
        this.imagesAlbumData.push(menuItem);
      })

    } finally {
      this.selectedSubcategory.title = "Galeri Video Baketrans";
      this.selectedSubcategory.description = "Badan Kebijakan Transportasi";
      this.selectedSubcategory.slug = "";
      this.isloading = false;
    }
  },
};
</script>

<style scoped>
.gallery-page {
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;

}

.gallerypage-info {
  padding: 10px;
}


.galeries-img {

  border: 2px solid #000;
  border-radius: 12px;
  background-color: #000;
}

.img-fluid {
  object-fit: cover;
  border-radius: 12px;
}

.menu {
  list-style: none;
  margin: 0;
  background-color: #001165;
  color: #fff;
  display: flex;
  padding: 10px;
  border-radius: 10px;
  font-weight: bold;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.menu:hover {
  background-color: #ffc41d;
  color: #fff;
}

.menu-item {
  position: relative;

}

.menu-item>a {
  text-decoration: none;
  display: block;
  padding: 10px;
  background-color: #fff;
  color: #000;
  cursor: pointer;

}

.menu-item>a:hover {
  background-color: #001165;
  color: #fff;
}

.blogcategories-list li {
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
}



.rightsidebar .card ul.blogcategories-list li span {
  background-color: #001165;
  color: #fff;
  border-radius: 3px;
  padding: 2px;
  min-width: 24px;
  max-width: 24px;
  height: 16px;
  text-align: center;
  vertical-align: middle;
  line-height: 13px;
  font-size: 11px;
  /* border-left: 2px solid #001165;
  border-right: 2px solid #001165; */
  position: absolute;
  right: 40px;
}


.rightsidebar .card ul.blogcategories-list li {
  list-style: none;
  font-weight: 500;
  text-transform: uppercase;
  /* border-left: 1px solid red; */
}

.submenu {
  overflow-y: auto;
}

.rightsidebar .card ul.blogcategories-list li .submenu-item>a {
  list-style: none;
  text-transform: none;
}

.rightsidebar .card ul.blogcategories-list li .submenu-item>a:before {
  content: "\f35a";
  font-family: FontAwesome;
  display: inline-block;
  margin-right: 10px;
  margin-left: 0;
  color: #001165;
}

.submenu-item>a {
  text-decoration: none;
  display: flex;
  padding: 5px;
  background-color: #fff;
  color: #000;
}

.submenu-item>a:hover {
  background-color: #fff;
}

.frame-title {
  font-size: 1rem;
  padding: 0;
  margin: 0;
}

.image-container img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 12px;
}


/* Zoom icon (hidden by default) */
.image-container::before {
  content: "\1F50D";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.7);
  opacity: 0;
  transition: opacity 0.5s ease;
}

/* Display zoom icon on hover */
.image-container:hover::before {
  opacity: 1;
}

/* Optional: Zoom effect on hover */
.image-container img:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}

.pagination .pagination-center {
  width: auto;
}



/*========================*/
.chat-users-list .chat-scroll a.media .media-img-wrap {
  margin-right: 1px;
  position: relative;
}

.media-img-wrap {
  margin: 0 15px 0 0;
}



.avatar {
  position: relative;
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 15px;
}

.chat-users-list a.media .user-name {
  color: #0d233e;
  margin: 0 0 4px;
  font-weight: 600;
}

.chat-cont-right .chat-body .chat-scroll {
  min-height: auto;
  max-height: auto;
  overflow: hidden;
}

.chat-cont-left .chat-users-list a.media {
  /* border: 1px solid #f1f1f1; */
  padding: 10px;
  /* transition: 0.5s; */
  /* border-radius: 10px; */
  margin: 0 0 5px;
}

.swal2-container {
  z-index: 10000 !important;
}

.swal2-popup {
  padding: 0.5rem 0 1.25em !important;
  border: 4px solid #ffc41d !important;
  border-radius: 20px !important;
  background: #000 !important;
  color: #fff !important;
  font-family: inherit !important;
  font-size: 0.7rem !important;
}

.swal2-close {
  width: 1em !important;
  height: 0.6em !important;
  margin-bottom: -1em !important;
}

.description {
  display: flex;
  padding-top: 10px;
}

.description p {
  width: 20vw;
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  color: #000;

  min-height: 27px;
  -webkit-line-clamp: 2 !important;
  display: -webkit-box !important;
  line-height: 1.2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: normal !important;
}

.viewer {
  padding-bottom: 40px;
}

.viewer span {
  font-size: 11px;
}

.viewer h5 {
  font-size: 12px;
  font-weight: normal;
  padding: 5px 0 0 0;
  margin: 0;
}

.description img {
  width: 24px;
  height: 24px;

  /* Make the image circular */


  margin: 0px 8px 8px 0px;

}

.galeries-box {
  position: relative;
  margin: 0 0 6px;
}
</style>