<template>
    <section class="directory-section">
        <div class="container">
            <div class="section-heading heading-eight pt-4">
                <div class="row align-items-center">
                    <div class="col-md-12  text-center">
                        <h2>{{ (lang == 'en' ? 'Directory' : 'Direktori') }}</h2>
                    </div>
                </div>
            </div>
            <div class="category-details">
                <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 d-flex" v-for="item in directoryData"
                        :key="item.id">
                        <a :href="item.link" class="category-inner flex-fill"
                            :target="(item?.target == '1' ? '_blank' : '_self')" aria-label="baketrans">
                            <div class="category-img-two">
                                <img v-lazy="fstring.getAssetpath(item?.icon)" class="dir-icon" alt="baketrans">
                            </div>
                            <div class="category-content">
                                <h4>{{ (item?.title ?? '') }}</h4>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="align-items-center justify-content-center d-flex">
                    <router-link to="/directory" class="btn btn-primary-light category-btn">
                        {{ (lang == 'en' ? 'View all directories' : 'Lihat semua direktori') }} <i
                            class="bi bi-arrow-right-circle"></i></router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { fstring } from '@/lib/fstring.js'
export default {
    props: ['directoryData'],
    data() {
        return {
            fstring: fstring,
            lang: fstring.getLang(),
        };
    },
}
</script>

<style scoped>
.dir-icon {
    height: 38px;
}

.directory-section {
    /* background: url(@/assets/media/images/bg/bg1.jpg) no-repeat;
    background-size: cover; */
    padding: 30px;
    background-color: #fff;

}

.category-btn {
    border: 1px solid #ebebeb;
    margin: 26px 0 0;
    padding: 10px 25px 10px 25px;
    display: inline-block;
    font-size: 0.9rem;
    border-radius: 25px;
    background-color: #001165;
    color: #fff;
}

.category-btn:hover {
    background: #ffc41d !important;
    color: #fff !important;
    border: 1px solid #ffc41d !important;
}
</style>