<template>
    <div class="modal-mask" v-if="isVisible" @click="closeOnClickOutside">
        <div class="modal-wrapper">
            <div class="modal-container">
                <!-- Modal body -->
                <div class="modal-body">
                    <slot>

                    </slot>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <slot name="footer">

                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isVisible: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
        closeOnClickOutside(event) {
            // Check if the clicked element is the modal mask itself (outside the modal content)
            if (event.target.classList.contains('modal-mask')) {
                this.closeModal();
            }
        }
    }
}
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 99998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.6s;
    /* Fade in effect */
}

.modal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: slideIn 0.5s forwards;

    /* Slide in effect */
}

.modal-container {
    max-width: 600px;
    min-width: 300px;
    height: auto;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 10px;

}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-bottom: 1px solid #eaeaea; */

}

.modal-footer {
    display: flex;
    justify-content: flex-end;
}

.close {
    background: none;
    border: none;
    font-size: 1.2em;
    cursor: pointer;
}

@media (max-width: 767.98px) {
    .modal-container {
        min-width: 100% !important;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* Slide in keyframes */
@keyframes slideIn {
    0% {
        transform: translate(0, -50%) scale(0.7);
        opacity: 0;
    }
}
</style>