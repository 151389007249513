<template>
    <section class="section-blk destination-blk bg-white">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="title-blk">
                        <div class="title-left">
                            <h2 class="p-0 m-0">{{ (lang == 'en' ? 'Latest News' : 'Berita terbaru') }}</h2>
                            <h5
                                v-html="(lang == 'en' ? 'The latest news about baketrans' : 'Lihat berita terbaru seputar baketrans')">
                            </h5>
                        </div>
                        <div class="theme-btn">
                            <router-link to="/berita" class="search-btn">
                                {{ (lang == 'en' ? 'See More News' : 'Lihat Selengkapnya') }}
                                <span>
                                    <i class="feather-arrow-right"></i>
                                </span>
                            </router-link>
                        </div>
                    </div>
                </div>
                <template v-if="beritaData">
                    <div class="col-lg-3 col-md-6 d-flex1" v-for="item in beritaData" :key="item.id">
                        <div class="blog grid-blog blog-four flex-fill">
                            <div class="blog-image p-2">
                                <router-link :to="{ name: 'pubs-berita-detail', params: { id: item?.slug } }">
                                    <img class="img-fluid" v-lazy="fstring.getAssetpath(item.thumbnail)"
                                        alt="baketrans">
                                </router-link>
                            </div>
                            <div class="blog-content">
                                <h3 class="blog-title p-0 m-0">
                                    <router-link :to="{ name: 'pubs-berita-detail', params: { id: item?.slug } }">
                                        {{ fstring.toProperCase(item?.title) }}
                                    </router-link>
                                </h3>
                                <ul class="entry-meta meta-item">
                                    <li>
                                        <div class="post-author">
                                            <div class="">
                                                <p><i class="bi bi-calendar-date me-2"></i> {{
                                fstring.formattedDate(item?.published) }}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <p class="blog-description">{{ item?.description }}</p>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>

<script>

import { fstring } from '@/lib/fstring.js'
export default {
    props: ['beritaData'],
    data() {
        return {
            fstring: fstring,
            lang: fstring.getLang(),
        };
    },
    methods: {

    },

};
</script>

<style scoped>
.blog-four {
    border-radius: 1rem;
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    min-height: 380px;
}

.blog-title a {
    font-size: 16px !important;
    font-weight: 500 !important;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
    margin: 0;
}

.blog-description {
    font-size: 12px !important;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
    height: auto !important;
    line-height: 20px !important;
}

.text-one-side {

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

}

.blog.grid-blog .post-author a {
    margin: 0 0 15px;
    font-size: 12px;
    color: #272727;
}

.blog-content p {
    font-size: 12px;
    margin: 0;
    color: #000;
}

.blog-content .entry-meta {
    margin-top: 5px !important;
    margin-bottom: 0;
}

.blog-content .post-author .post-author-img img {
    width: 40px;
    height: 40px;
    border-radius: 0;

}

.grid-blog .entry-meta li {
    margin: 0;
    /* font-size: 0; */
    color: #717171;
}

.blog-image img {
    min-height: 180px;
    border-radius: 1rem !important;
    object-fit: cover !important;
    color: transparent !important;
}

.theme-btn {

    background: #E4FBFF;
    color: #ffffff;
    font-weight: 300 !important;
}

.theme-btn a {
    font-size: 14px;
    font-weight: 700;
    padding: 0;
    text-transform: capitalize;
    color: #ffffff;
}

.theme-btn a span {
    background: #fff;
    border-radius: 100px;
    width: 40px;
    height: 40px;
    color: #001165;
    margin-left: 10px;
}

.theme-btn a span:hover {
    background: #8fd8ff !important;
    color: #001165 !important;
}

.theme-btn:hover {
    background: #ffc41d !important;
    color: #fff !important;
    border: 1px solid #ffc41d !important;
}

.new-bg {
    background-color: #90E0EF;
}

.grid-blog .post-author {
    padding-bottom: 10px;

}


@media (max-width: 991.98px) {
    .grid-blog .post-author {
        width: auto;

    }
}
</style>