<template>
	<footer class="footer pt-5" v-if="!$isloading">
		<div class="container d-flex justify-content-center">
			<div class="stay-tuned">
				<img v-lazy="`/assets/images/logo.png`" width="80" class="img-fluid p-2" alt="logo">
				<p class="p-0">Badan Kebijakan Transportasi - Kementrian Perhubungan</p>
				<h4 class="p-0 m-0"
					v-html="lang == 'en' ? 'Get the latest information about Baketrans' : 'Dapatkan info terbaru mengenai Baketrans'">
				</h4>
				<p class="p-0"
					v-html="lang == 'en' ? 'Subscribe to get the latest information about baketrans' : 'Berlangganan untuk mendapatkan informasi terbaru seputar baketrans'">
				</p>
				<b-form>
					<div class="form-group">
						<div class="group-img">
							<i class="feather-mail"></i>
							<b-form-input type="text" class="form-control" placeholder="Enter Email Address" />
						</div>
					</div>
					<b-button variant="primary" type="submit"> Subscribe</b-button>
				</b-form>
			</div>
		</div>

		<div class="footer-top mb-0 pb-4">
			<div class="container">
				<div class="row">
					<div class="col-lg-2 col-md-6">
						<div class="footer-widget footer-menu" v-if="medsosData?.footer">
							<h2 class="footer-title">{{ lang == 'en' ? 'Visitor' : 'Pengunjung' }}</h2>
							<ul>
								<li>
									<a href="javascript:;" class="visitor">
										<span>{{ lang == 'en' ? 'Today' : 'Hari Ini' }}</span>
										<span>{{ fstring.ToCurrency(medsosData?.statistics?.hari) }}</span></a>
								</li>
								<li>
									<a href="javascript:;" class="visitor">
										<span>{{ lang == 'en' ? 'This Week' : 'Minggu Ini' }}</span>
										<span>{{ fstring.ToCurrency(medsosData?.statistics?.minggu) }}</span></a>
								</li>
								<li>
									<a href="javascript:;" class="visitor">
										<span>{{ lang == 'en' ? 'This Month' : 'Bulan Ini' }}</span>
										<span>{{ fstring.ToCurrency(medsosData?.statistics?.bulan) }}</span></a>
								</li>
								<li>
									<a href="javascript:;" class="visitor">
										<span>{{ lang == 'en' ? 'This Year' : 'Tahun Ini' }}</span>
										<span>{{ fstring.ToCurrency(medsosData?.statistics?.tahun) }}</span></a>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-lg-1 col-md-6">

					</div>
					<div class="col-lg-2 col-md-6">
						<div class="footer-widget footer-menu" v-if="medsosData?.footer">
							<h2 class="footer-title">{{ lang == 'en' ? 'Services' : 'Layanan' }}</h2>
							<ul v-for="(footerlink, index) in medsosData?.footer?.group2" :key="index">
								<li>
									<a v-if="fstring.isExternalUrl(footerlink?.link)" :href="footerlink?.link"
										:target="(footerlink?.target == '1' ? '_blank' : '_self')"
										aria-label="footerlink">
										<span v-html="(footerlink?.title ?? '')"></span>
									</a>
									<router-link v-if="!fstring.isExternalUrl(footerlink?.link)" :to="footerlink?.link"
										:target="(footerlink?.target == '1' ? '_blank' : '_self')"
										aria-label="footerlink">
										<span v-html="(footerlink?.title ?? '')"></span>
									</router-link>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-lg-2 col-md-6">
						<div class="footer-widget footer-menu" v-if="medsosData?.footer">
							<h2 class="footer-title">{{ lang == 'en' ? 'Directory' : 'Direktori' }}</h2>
							<ul v-for="(footerlink, index) in medsosData?.footer?.group3" :key="index">
								<li>
									<a v-if="fstring.isExternalUrl(footerlink?.link)" :href="footerlink?.link"
										:target="(footerlink?.target == '1' ? '_blank' : '_self')"
										aria-label="footerlink">
										<span v-html="(footerlink?.title ?? '')"></span>
									</a>
									<router-link v-if="!fstring.isExternalUrl(footerlink?.link)" :to="footerlink?.link"
										:target="(footerlink?.target == '1' ? '_blank' : '_self')"
										aria-label="footerlink">
										<span v-html="(footerlink?.title ?? '')"></span>
									</router-link>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-lg-2 col-md-6">
						<div class="footer-widget footer-menu" v-if="medsosData?.footer">
							<h2 class="footer-title">{{ lang == 'en' ? 'Other' : 'Lainnya' }}</h2>
							<ul v-for="(footerlink, index) in medsosData?.footer?.group4" :key="index">
								<li>
									<a v-if="fstring.isExternalUrl(footerlink?.link)" :href="footerlink?.link"
										:target="(footerlink?.target == '1' ? '_blank' : '_self')"
										aria-label="footerlink">
										<span v-html="(footerlink?.title ?? '')"></span>
									</a>
									<router-link v-if="!fstring.isExternalUrl(footerlink?.link)" :to="footerlink?.link"
										:target="(footerlink?.target == '1' ? '_blank' : '_self')"
										aria-label="footerlink">
										<span v-html="(footerlink?.title ?? '')"></span>
									</router-link>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-lg-3 col-md-6">
						<div class="footer-widget">
							<h2 class="footer-title">{{ lang == 'en' ? 'Contact Us' : 'Kontak' }}</h2>
							<div class="footer-contact-info">
								<div class="footer-address">
									<i class="bi bi-telephone-fill"></i>
									<p><span>{{ lang == 'en' ? 'Call Us' : 'Hubungi Kami' }}</span> <br>
										{{ (medsosData?.phone ?? '') }} </p>
								</div>
								<div class="footer-address">
									<i class="bi bi-envelope-fill"></i>
									<p><span>{{ lang == 'en' ? 'Email Us' : 'Kirim Email' }}</span> <br>
										{{ (medsosData?.email ?? '') }} </p>
								</div>
								<div class="footer-widget">
									<div class="social-icon">
										<ul v-for="(item, index) in medsosData.medsos" :key="index">
											<li>
												<a :href="item?.link" target="_blank" class="me-2" aria-label="medsos">
													<i :class="item?.icon"></i>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="footer-bottom">
			<div class="container">
				<div class="copyright">
					<div class="row">
						<div class="col-md-6">
							<div class="copyright-text">
								<p class="mb-0">Copyright © {{ new Date().getFullYear() }} - Baketrans.
								</p>
							</div>
						</div>
						<div class="col-md-6">
							<div class="copyright-menu">
								<ul class="policy-menu">
									<li>
										<a href="javascript:;" aria-label="policy"><i
												class="bi bi-calendar-date me-1"></i>
											{{ currentDateTime }}
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import { fstring } from '@/lib/fstring.js'
export default {
	props: ['medsosData'],
	data() {
		return {
			fstring: fstring,
			lang: fstring.getLang(),
			currentDateTime: ''
		}
	},
	created() {

	},
	mounted() {
		this.updateTime();
		setInterval(this.updateTime, 1000);
	},
	watch: {
		async $route(to, from) {

		}
	},
	methods: {
		updateTime() {
			const now = new Date();

			// Array of day names
			let daysOfWeek = [];
			if (this.lang == 'en') {
				daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
			} else {
				daysOfWeek = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
			}

			// Get the current day name
			const dayName = daysOfWeek[now.getDay()]; // Get day of the week

			// Get the current date in YYYY-MM-DD format
			const year = now.getFullYear();
			const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
			const day = now.getDate().toString().padStart(2, '0');

			// Get the current time in 24-hour format (HH:MM:SS)
			const hours = now.getHours().toString().padStart(2, '0');
			const minutes = now.getMinutes().toString().padStart(2, '0');
			const seconds = now.getSeconds().toString().padStart(2, '0');

			// Combine the day name, date, and time
			this.currentDateTime = `${dayName}, ${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
		}
	}
}
</script>



<style scoped>
.footer .footer-logo img {
	width: 80% !important;
	/* Add other styles if needed */
}

.footer .footer-logo p {
	margin: 0;
	padding: 10px 0 0 0;
	color: #fff;
	font-size: 11px;
}

.footer .footer-bottom {
	font-size: 12px;
	padding: 5px;
}

.footer .footer-bottom .copyright {
	padding: 5px 0;
}

.footer .footer-bottom .copyright p {
	font-size: 12px !important;
}

.footer-address i {
	font-size: 28px;
	margin-right: 10px;
	color: #ffc41d;

}

.footer-title {
	color: #ffc41d;
}

.copyright-menu .policy-menu li a {
	font-size: 12px !important;
}

.new-bg {
	background-color: #90E0EF;
}


.footer .footer-top .footercount {
	margin: 0;
	padding: 5px 0 5px;
	margin-top: 5px;
	border-top: 1px dashed #0c198d;
}

.footercount p {
	font-size: 10px;
	margin-bottom: 0px;
}

.footer .footer-top .footercount p {
	color: #ffc41d;
}

.visitor {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	cursor: default !important;
}

.small-font a {
	font-size: 11px !important;
}


@media (max-width: 991.98px) {
	.footer .footer-top .footercount {
		margin: 0;
		padding: 5px 0 5px;
	}

	.footercount p {
		margin-bottom: 0px;
	}
}
</style>