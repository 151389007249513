import axiosApi from '@/lib/axios';
import { start, complete } from '../assets/plugins/nanobar/nanobar.js';
const nanoStart = start;
const nanocomplete = complete;

const kegiatanPage = {
    namespaced: true,
    strict: false,
    state: {
        kegiatan_list: [],
        kegiatan_detail: [],
        kegiatan_mark: [],
    },
    mutations: {
        SET_KEGIATAN_LIST(state, payload) {
            state.kegiatan_list = payload
        },
        SET_KEGIATAN_DETAIL(state, payload) {
            state.kegiatan_detail = payload
        },
        SET_KEGIATAN_MARK(state, payload) {
            state.kegiatan_mark = payload
        },

    },
    actions: {
        getKegiatanList({ commit }, params) {
            //nanoStart();
            return new Promise((resolve, reject) => {
                axiosApi.post('kegiatan-page/list', { s: params[0], e: params[1] })
                    .then((response) => {
                        commit('SET_KEGIATAN_LIST', response.data.data)
                        resolve(response.data)
                        //nanocomplete();
                    })
                    .catch((error) => {
                        //nanocomplete();
                        //reject(error)
                    })
            })
        },
        getKegiatanMark({ commit }, params) {
            //nanoStart();
            return new Promise((resolve, reject) => {
                axiosApi.post('kegiatan-page/mark', { y: params[0], m: params[1] })
                    .then((response) => {
                        commit('SET_KEGIATAN_MARK', response.data.data)
                        resolve(response.data)
                        //nanocomplete();
                    })
                    .catch((error) => {
                        //nanocomplete();
                        //reject(error)
                    })
            })
        }
    }
};

export default kegiatanPage