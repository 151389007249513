<template>
  <div class="row">
    <div class="col-lg-9">
      <div class="bloglistleft-widget blog-listview">
        <h3 class="p-0 m-0 text-muted font-weight-normal pb-3">
          <i @click="$router.go(-1)" class="bi bi-arrow-left-circle cursor-pointer"></i> <span
            class="text-capitalize">{{
            (options.sTitle == undefined || options.sTitle == '' ? (lang == 'en' ? 'News' : 'Berita') :
              options.sTitle) }}</span>
          <span class="float-end fs-11" v-if="totalRecord > 0">
            {{ lang == 'en' ? 'Found' : 'Ditemukan' }} {{ totalRecord }} item
          </span>
          <span class="float-end fs-11" v-else="">
            {{ lang == 'en' ? 'No data' : 'Tidak ada data' }}
          </span>
        </h3>

        <template v-if="baseData.news">
          <div v-show="totalRecord < 1">
            <div class="container">
              <div class="row">
                <div class="col-sm-12 col-md-12">
                  <div class="banner-info">
                    <h2 class="p-0 m-0">{{ lang == 'en' ? 'No data' : 'Tidak ada data' }}.</h2>
                    <p class="p-0">{{ lang == 'en' ? 'Keywords' : 'Kata kunci' }} <strong>'{{ options.sQuerySubmit
                        }}'</strong>
                      {{ lang == 'en' ? `not found in our system, please try with other keywords!` : ` tidak ditemukan
                      dalam sistem kami, silahkan coba dengan kata
                      kunci lain !` }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card" v-for="item in baseData.news.data">
            <div class="blog-widget card-body">
              <div class="blog-img">
                <a @click.prevent="callshowImage(fstring.getAssetpath(item?.image))" aria-label="baketrans">
                  <img class="img-strecth" v-lazy="fstring.getAssetpath(item.thumbnail)" width="100%" height="400"
                    alt="baketrans" />
                </a>
              </div>
              <div class="bloglist-content">
                <div class="p-2">
                  <div class="review-details">
                    <h3 class="blog-title ellipsis3">
                      <router-link
                        :to="(baseData?.categories?.group == 'news' ? `/berita/${item?.slug}` : (baseData?.categories?.group == 'news' ? `/berita/${item?.slug}` : `/publikasi/view/${item?.slug}`))">
                        {{ item?.title }}
                      </router-link>
                    </h3>
                    <ul class="entry-meta meta-item">
                      <li>
                        <div class="post-author">
                          <a href="javascript:void(0)" aria-label="Author"><span> {{
            fstring.formattedDate(item?.published) + ` (` + fstring.timeAgo(item?.published) + `)` }}
                            </span></a>
                        </div>
                      </li>
                      <li class="date-icon p-0">
                        <i class="bi bi-eye"></i>
                        <span class="pe-3"> {{ item?.views }}x {{ lang == 'en' ? ' views ' : ' dilihat ' }}</span>
                      </li>
                    </ul>

                    <p class="mb-0 ellipsis4 p-2 text-black">{{ item?.description }}</p>
                    <div class="reply-box ">
                      <p>
                        <span class="thumbsup fs-11"><i class="bi bi-person-lines-fill pe-1"></i>
                          <strong class="text-bkt-blue">{{ item?.writeby }}</strong></span>
                      </p>
                      <p>
                        <router-link class="replylink btn btn-primary p-2 m-0"
                          :to="(baseData?.categories?.group == 'news' ? `/berita/${item?.slug}` : `/publikasi/view/${item?.slug}`)">
                          {{ lang == 'en' ? 'See More' : 'Selengkapnya' }}
                          <i class="feather-arrow-right-circle"></i>
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="col-lg-3 theiaStickySidebar">
      <div class="stickysidebar">
        <div class="card">
          <div class="card-body pb-1">
            <h6><i class="bi bi-search"></i> {{ lang == 'en' ? 'Search' : 'Cari' }}</h6>
            <div class="input-group mb-3">
              <input type="text" class="form-control" v-model="options.sQuery"
                :placeholder="lang == 'en' ? 'Enter keywords...' : 'Masukkan kata kunci...'" maxlength="100"
                aria-label="search" aria-describedby="button-search" :disabled="options.isloading"
                @keypress="callcheckEnter">
              <button class="btn btn-outline-secondary" type="button" id="button-search" @click="callsearchData"
                :disabled="options.isloading" aria-label="search">
                <i v-if="options.isloading" class="spinner-border spinner-border-sm align-middle"></i>
                <i v-else class="bi bi-search"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="rightsidebar blogsidebar">
          <div class="card">
            <h4><i class="bi bi-briefcase"></i> {{ lang == 'en' ? 'Categories' : 'Kategori' }}</h4>
            <ul class="blogcategories-list p-0">
              <li v-for="(row, index) in baseDataKategori">
                <a class="category-title" href="javascript:;" @click.prevent="callchangeCategories(row, index)"
                  v-if="!row.uioptions.loading" aria-label="loading">
                  <p class="category-title">{{ row?.title }}</p>
                  <span>{{ row?.total }} </span>
                </a>
                <a href="javascript:;" class="category-title" v-if="row.uioptions.loading" aria-label="loading">
                  <p class="category-title">{{ row?.title }}</p>
                  <i class="spinner-border spinner-border-sm align-middle me-2"></i>
                  <span>{{ row?.total }} </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="rightsidebar blogsidebar mt-4">
          <div class="card tags-widget">
            <h4><i class="feather-tag"></i> {{ lang == 'en' ? 'Tags' : 'Tagar' }}</h4>
            <ul class="tags">
              <li>#Kebijakan</li>
              <li>#Infrastruktur</li>
              <li>#Keselamatan</li>
              <li>#Transportasi</li>
              <li>#Darat</li>
              <li>#Laut</li>
              <li>#Udara</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fstring } from '@/lib/fstring.js'


export default {
  props: {
    options: {
      type: Object,
      default: null
    },
    baseData: {
      type: Object,
      default: null
    },
    baseDataKategori: {
      type: Object,
      default: []
    },
    totalRecord: {
      type: Number,
      default: 0
    },
    sCategory: {
      type: String,
      default: ''
    },
    sQuerySubmit: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      fstring: fstring,
      lang: fstring.getLang(),
      fstring: fstring
    }
  },
  components: {

  },
  computed: {
  },
  methods: {
    callshowImage(url) {
      this.$emit('showImage', url);
    },
    callcheckEnter(event) {
      this.$emit('checkEnter', event);
    },
    async callsearchData() {
      await this.$emit('searchData');
    },
    async callchangeCategories(value, index) {
      await this.$emit('changeCategories', value, index);
    }
  },
  async mounted() {

    try {

    } finally {

    }
  },
}

</script>


<style scoped>
.blog-img {
  width: 270px;
  max-height: auto;
  overflow: hidden;
  /* border-radius: 1rem 0 0 1rem !important; */
  border-radius: 0;
}

.blog-img img {
  width: 100% !important;
  object-fit: scale-down;
  /* border: 1px solid #F1F1F1; */
  border-radius: 0;
}

.bloglistleft-widget.blog-listview .blog-img {
  border-radius: 0;
  top: -25px !important;
}

.blog-title {
  text-transform: uppercase;
  font-size: 18px !important;
  font-weight: 500;
}

.blog-listview .card .blog-widget {
  width: 103%;
}

@media (max-width: 767.98px) {
  .blog-listview .card .blog-widget {
    width: 100%;
  }
}

.ellipsis3 {
  -webkit-line-clamp: 3 !important;
  display: -webkit-box;
  line-height: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.ellipsis4 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  line-height: 1.5em;
  max-height: 5.5em;
}

.review-details .rating div {
  font-size: 12px;
}

.rating i {
  font-size: 10px;
}

.icon32x32 {
  height: 24px;
  width: 24px;
  opacity: 0.7;
}

.img-strecth {
  width: 270px !important;
  height: 160px !important;
}

.rightsidebar .card ul.blogcategories-list li {
  list-style: none;
  position: relative;
}

.rightsidebar .card ul.blogcategories-list li:before {
  content: "\f35a";
  font-family: FontAwesome;
  color: #001165;
  position: absolute;
  margin-right: 10px;
}

.rightsidebar .card ul.blogcategories-list li span {
  background-color: #001165;
  color: #fff;
  border-radius: 10px;
  padding: 2px;
  min-width: 35px;
  max-width: 50px;
  height: 16px;
  text-align: center;

  line-height: 13px;
  font-size: 11px;

}

.category-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 !important;
  margin: 0 !important;
  margin-left: 10px !important;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}

.review-details .reply-box {
  margin: 15px 20px 0 0;
}

.review-details .reply-box p a {
  font-size: 12px !important;
  padding: 5px !important;
  margin: 0 !important;
}

.btn-outline-secondary {
  border-color: #dcdcdc !important;
}

.btn-primary {
  border-radius: 10px;
  border-color: #001165;
  background-color: #001165;
}

.bloglistleft-widget .entry-meta {
  margin: 0 0 2px;
}
</style>