<template>
  <header class="header header-nine">
    <div class="header-top">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <ul class="d-flex justify-content-start car-top-left">
              <div v-for="(item, index) in medsosData.medsos" :key="index">
                <li class="d-flex align-items-center">
                  <a :href="item?.link" target="_blank" class="me-2" aria-label="medsos">
                    <i :class="item?.icon"></i>
                  </a>
                </li>
              </div>

              <li class="d-flex align-items-center">|</li>
              <li class="d-flex align-items-center header-link baketrans-color">
                <i class="feather-phone me-2"></i> {{ (medsosData?.phone ?? '') }}
              </li>
              <li class="d-flex align-items-center">|</li>
              <li class="d-flex align-items-center baketrans-color">
                <i class="feather-mail me-2 "></i> {{ (medsosData?.email ?? '') }}
              </li>

            </ul>
          </div>
          <div class="col-md-4 d-flex justify-content-end align-items-center">

            <button class="fullscreen-button" @click="toggleFullscreen" aria-label="fullscreen">
              <i class="bi bi-arrows-fullscreen"></i>
            </button>
            <SearchPopup ref="searchPopup" />
            <div class="language-dropdown" ref="dropdown">
              <button class="dropdown-toggle" @click="toggleDropdown" aria-label="dropdown">
                <span class="flag-icon" :style="{ backgroundImage: 'url(' + currentFlag + ')' }"></span>
                <!-- No text displayed here -->
                <i class="feather-chevron-down"></i>
              </button>
              <div class="dropdown-menu" v-if="isDropdownOpen">
                <div class="dropdown-item" @click="selectLanguage('id')">
                  <span class="flag-icon" :style="{ backgroundImage: 'url(' + flags?.id + ')' }"></span>
                  <div class="language-info">
                    <div class="country-name">Indonesia</div>
                    <div class="language-name">Bahasa Indonesia</div>
                  </div>
                </div>
                <div class="dropdown-item" @click="selectLanguage('en')">
                  <span class="flag-icon" :style="{ backgroundImage: 'url(' + flags?.gb + ')' }"></span>
                  <div class="language-info">
                    <div class="country-name">English</div>
                    <div class="language-name">United States</div>
                  </div>
                </div>
                <div class="dropdown-item" @click="changeLanguage('en')">
                  <span class="flag-icon"><i class="bi bi-google text-primary"></i></span>
                  <div class="language-info">
                    <div class="country-name">Auto Translate</div>
                    <div class="language-name">Google Translate</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container1 ps-5 pe-5">
      <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header">
          <a id="mobile_btn" href="javascript:;" aria-label="menu">
            <span class="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
          <router-link to="/" class="navbar-brand logo">
            <img src="@/assets/img/baketrans.png" class="img-fluid" alt="Logo">
          </router-link>
        </div>
        <div class="main-menu-wrapper">
          <div class="menu-header">
            <router-link to="/" class="menu-logo">
              <img src="@/assets/img/baketrans.png" class="img-fluid" alt="Logo">
            </router-link>
            <a id="menu_close" class="menu-close" href="javascript:void(0);" aria-label="close"> <i
                class="fas fa-times"></i></a>
          </div>
          <app-nav-menu :menu="medsosData.menu" />
        </div>
      </nav>
    </div>
    <app-newsticker />
    <div class="separator-nav">

    </div>
  </header>
</template>

<script>
import AppNavMenu from './navmenu.vue';
import SearchPopup from './searchpopup.vue';
import AppNewsticker from './newsticker.vue';

export default {
  components: {
    SearchPopup,
    AppNavMenu,
    AppNewsticker
  },
  props: ['medsosData'],

  data() {
    return {
      isFullscreen: false,
      isSearchPopupOpen: false,
      isDropdownOpen: false,
      currentLanguage: 'Indonesian',
      currentFlag: require('@/assets/img/flags/id.svg'),
      flags: {
        gb: require('@/assets/img/flags/gb.svg'),
        id: require('@/assets/img/flags/id.svg')
      },
      currentIndex: 0,
      autoplayInterval: null,
    };
  },
  methods: {
    toggleFullscreen() {

      try {

        const element = document.getElementById("app");

        if (!this.isFullscreen) {
          if (element.requestFullscreen) {
            element.requestFullscreen();
          } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen(); // Firefox
          } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen(); // Chrome, Safari, and Opera
          } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen(); // IE/Edge
          }
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen(); // Firefox
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen(); // Chrome, Safari, and Opera
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen(); // IE/Edge
          }
        }
        this.isFullscreen = !this.isFullscreen;



      } catch (error) {

      } finally {
        if (this.isFullscreen) {
          const element = document.getElementById("app");
          element.classList.add("fullscreen");
        } else {
          const element = document.getElementById("app");
          element.classList.remove("fullscreen");
        }
      }
    },
    checkFullscreen() {

      if (document.fullscreenElement == null) {
        this.isFullscreen = false;
        const element = document.getElementById("app");
        element.classList.remove("fullscreen");
      } else {
        this.isFullscreen = !!document.fullscreenElement
      }

    },
    toggleSearchPopup() {
      this.isSearchPopupOpen = !this.isSearchPopupOpen;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectLanguage(lang) {
      if (lang === 'en') {
        this.currentLanguage = 'English';
        this.currentFlag = this.flags.gb;
      } else if (lang === 'id') {
        this.currentLanguage = 'Indonesian';
        this.currentFlag = this.flags.id;
      }
      localStorage.setItem("x-lang", lang)
      this.isDropdownOpen = false;
      window.location.reload();
    },
    handleClickOutside(event) {
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
        this.isDropdownOpen = false;
      }
    },
    prevSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      } else {
        this.currentIndex = this.newsItems.length - 1;
      }
    },
    nextSlide() {
      if (this.currentIndex < this.newsItems.length - 1) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0;
      }
    },
    autoplay() {
      this.autoplayInterval = setInterval(() => {
        this.nextSlide();
      }, 3000); // Change slide every 3 seconds
    },
    onSearchClick() {
      // Handle search icon click
      //console.log('Search icon clicked');
    },
    changeLanguage(language) {
      // Redirect the page to a Google-translated version of the current page
      const url = `https://translate.google.com/translate?hl=${language}&sl=auto&tl=${language}&u=${window.location.href}`;
      window.open(url);
    },
  },
  mounted() {

    const lang = localStorage.getItem("x-lang");
    if (lang == 'en') {
      this.currentFlag = this.flags.gb;
    } else {
      this.currentFlag = this.flags.id;
    }

    document.addEventListener('click', this.handleClickOutside);
    $('body').append('<div class="sidebar-overlay"></div>');
    $(document).on('click', '#mobile_btn', function () {
      $('main-wrapper').toggleClass('slide-nav');
      $('.sidebar-overlay').toggleClass('opened');
      $('html').addClass('menu-opened');
      return false;
    });

    $(document).on('click', '.sidebar-overlay', function () {
      $('html').removeClass('menu-opened');
      $(this).removeClass('opened');
      $('main-wrapper').removeClass('slide-nav');
    });

    $(document).on('click', '#menu_close', function () {
      $('html').removeClass('menu-opened');
      $('.sidebar-overlay').removeClass('opened');
      $('main-wrapper').removeClass('slide-nav');
    });

    document.addEventListener('fullscreenchange', this.checkFullscreen);
    document.addEventListener('mozfullscreenchange', this.checkFullscreen); // Firefox
    document.addEventListener('webkitfullscreenchange', this.checkFullscreen); // Chrome, Safari, Opera
    document.addEventListener('msfullscreenchange', this.checkFullscreen); // IE/Edge

  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
    // Clean up event listeners when the component is destroyed
    document.removeEventListener('fullscreenchange', this.checkFullscreen);
    document.removeEventListener('mozfullscreenchange', this.checkFullscreen);
    document.removeEventListener('webkitfullscreenchange', this.checkFullscreen);
    document.removeEventListener('msfullscreenchange', this.checkFullscreen);

    const element = document.getElementById("app");
    element.classList.remove("fullscreen");

  }
}
</script>

<style scoped>
.language-dropdown {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.dropdown-toggle {
  background: transparent;
  border: none;
  color: #000;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.flag-icon {
  width: 20px;
  height: 20px;
  background-size: cover;
  margin-right: 4px;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: 250px;
  /* Adjust width as needed */
  padding: 8px 0;
  /* Padding inside the card */
  z-index: 1000;
}

.dropdown-item {
  padding: 2px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s;
}

.dropdown-item:last-child {
  border-bottom: none;
}

.language-info {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}

.country-name {
  font-weight: bold;
}

.language-name {
  font-size: 10px;
  color: #555;
}





.search-icon {

  /* Adjust this value as needed */
  color: #001165;

  font-size: 15px;
  /* font-size: 1.5rem; Adjust font size if needed */
}

.search-button {
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  padding-left: 5px;
  padding-right: 5px;
}

.fullscreen-button {
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.separator-nav {
  height: 2px;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
}

.twiter-color {
  color: #1DA1F2;
}

.facebook-color {
  color: #1877F2;
}

.instagram-color {
  color: #C13584;
}

.youtube-color {
  color: #FF0000;
}

.baketrans-color {
  color: #001165 !important;
}

.header-link {
  font-weight: 700;
  line-height: 1;
  white-space: nowrap;
  vertical-align: baseline;
  color: rgb(163 8 8);
}

/* .header .header-nav {

  width: 80vw !important;

} */
</style>