<template>
    <template v-if="filetype === 'pdf' && filename != ''">
        <div class="col-xs-12" style="padding-bottom:30px">
            <div class="_df_book" height="auto" webgl="true" backgroundcolor="white" startZoom="2.5" :source="filename"
                enableDownload="false" id="df_manual_book">
            </div>
        </div>
    </template>
</template>

<script>

export default {
    props: {
        filename: {
            type: String,
            default: ''
        },
        filetype: {
            type: String,
            default: ''
        }
    },
    data() {
        return {

        };
    },

    mounted() {
        this.removeJS('/assets/plugins/dflip/js/libs/jquery.min.js');
        this.removeJS('/assets/plugins/dflip/js/dflip.min.js');
        this.removeCSS('/assets/plugins/dflip/css/dflip.min.css');
        this.removeCSS('/assets/plugins/dflip/css/themify-icons.min.css');


        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = '/assets/plugins/dflip/css/dflip.min.css';
        document.head.appendChild(link);

        const link2 = document.createElement('link');
        link2.rel = 'stylesheet';
        link2.href = '/assets/plugins/dflip/css/themify-icons.min.css';
        document.head.appendChild(link2);

        const jscript = document.createElement('script');
        jscript.src = '/assets/plugins/dflip/js/libs/jquery.min.js';
        document.body.appendChild(jscript);

        const script = document.createElement('script');
        script.src = '/assets/plugins/dflip/js/dflip.min.js';
        document.body.appendChild(script);

        document.querySelector('#df_manual_book').addEventListener('wheel', function (event) {
            // Prevent zooming by stopping the default scroll action
            event.preventDefault();
        }, { passive: false });
    },
    methods: {
        removeJS(src) {
            var scripts = document.querySelectorAll('script');
            for (var i = 0; i < scripts.length; i++) {
                if (scripts[i].getAttribute('src') === src) {
                    scripts[i].parentNode.removeChild(scripts[i]);
                }
            }
        },
        removeCSS(href) {
            var links = document.querySelectorAll('link[rel="stylesheet"]');
            for (var i = 0; i < links.length; i++) {
                if (links[i].getAttribute('href') === href) {
                    links[i].parentNode.removeChild(links[i]);
                }
            }
        }
    }

};
</script>

<style scoped>
#magazine {
    width: 1152px;
    height: 752px;
    margin: 0 auto;
    position: relative;
    box-shadow: 0 0 10px rgba(73, 66, 66, 0.471);
}

#magazine .turn-page {
    background-color: #ccc;
    background-size: cover;
    background-position: center;
    width: 576px;
    height: 752px;
    box-sizing: border-box;
    border: 1px solid #ddd;
}

#magazine .turn-page {
    cursor: pointer;
}

#magazine .turn-page:hover {
    background-color: #f0f0f0;
}

#magazine .turn-page:active {
    cursor: grabbing;
}

.controls {
    width: 1152px;
    margin: 20px auto;
    text-align: center;
}

.controls button {
    padding: 10px 20px;
    margin: 0 10px;
    font-size: 18px;
    cursor: pointer;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
}

.controls button:hover {
    background-color: #555;
}
</style>