<template>
  <div class="main-wrapper">
    <div class="page-wrapper about-container">
      <app-bread-crumb homeText="Beranda" :path="data.link" :homeRoute="data.name" :title="data.title"
        :description="data.description" />
      <div class="container">
        <section class="about-content">
          <div class="container">
            <div class="row">
              <div class="col-lg-10">
                <div class="about-baketrans">
                  <div class="about-img">
                    <img v-lazy="fstring.getAssetpath(baseData?.image)" class="img-fluid" alt="baketrans">
                  </div>
                  <div class="about-info">
                    <h4 class="text-title m-0 p-0">{{ baseData?.nama }}</h4>
                    <p class="text-jabatan">
                      <router-link :to="baseData?.unitkerja_url">
                        {{ baseData?.jabatan }}
                      </router-link>
                    </p>
                    <p class="text-description" v-html="baseData?.content"></p>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-12">
                <div class="stickysidebar pt-4">
                  <h4 class="fs-13"><i class="feather-link text-warning"></i> Link Terakit</h4>
                  <ul class="blogcategories-list">
                    <template v-for="(pejabat, index)  in  baseDataList " :key="pejabat.id">
                      <li>
                        <router-link :to="`/profiles/pejabat/${pejabat.slug}`">
                          {{ pejabat.jabatan }}
                        </router-link>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex'
import AppBreadCrumb from '@/views/layouts/bread-crumb.vue'
import { fstring } from '@/lib/fstring.js'
export default {
  components: {
    AppBreadCrumb
  },
  data() {
    return {
      data: {
        isloading: false,
        name: "/",
        title: "Profil Pejabat",
        description: "",
        link: [
          {
            title: "Profil",
            path: "/profiles/informasi-pejabat",
            description: ""
          },
          {
            title: "Informasi Pejabat",
            path: "/profiles/informasi-pejabat",
            description: ""
          }
        ]
      },
      info: {
        nama: "",
        jabatan: "",
        kantor: "",
        tempatlahir: "",
        tgllahir: "",
        riwayat: "",
        image: "",
        thumb: ""
      },
      shapeFlag: false,
      fstring: fstring
    };
  },

  computed: {
    ...mapState('pejabatPage', {
      baseData: (state) => state.pejabat_detail
    }),
    ...mapState('pejabatPage', {
      baseDataList: (state) => state.pejabat_list
    })
  },
  methods: {
    ...mapActions('pejabatPage', ['getPejabatDetail']),
    ...mapActions('pejabatPage', ['getPejabatList'])
  },
  async created() {
    this.isloading = true;
    await this.getPejabatDetail(this.$route.params.id);
    await this.getPejabatList();
    this.isloading = false;
  },
  watch: {
    async $route(to, from) {
      this.isloading = true;
      try {
        await this.getPejabatDetail(to.params.id);
      } finally {
        this.isloading = false;
      }
    }
  },
};
</script>

<style>
.about-baketrans {
  position: relative;
  top: 10px !important;
}

.about-content,
.container {
  background-color: transparent !important;
}

.about-container {
  background: url('@/assets/img/bg/bg-logo.jpg') !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;

}

.text-title {
  font-size: 1.7em !important;
  color: #001165 !important;
}

.text-jabatan {
  font-weight: bold;
  font-size: 1.2em;
}

.text-description {
  text-align: justify;

}

.about-info {
  border: 1px solid #f3f3f3;
  box-shadow: 0px 4.4px 20px -1px rgba(19, 16, 34, 0.05), 0px 4px 9px -1px rgba(19, 16, 34, 0.03);
  width: 60% !important;

}

.about-info p {
  line-height: 23px;

}

.about-img img {
  width: 40% !important;
}

.ul-custom {
  list-style: initial;
  padding: 10px;
  margin: 10px;
}

@media (max-width: 1124px) {
  .about-img img {
    width: 100% !important;
  }

  .about-info {
    width: 100% !important;
  }
}
</style>