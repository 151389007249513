<template>
    <div class="main-wrapper">
        <div class="page-wrapper">
            <app-bread-crumb homeText="Beranda" :path="data.link" :homeRoute="data.name" :title="data.title"
                :description="data.description" />
            <div class="direktori-layanan pt-4">
                <div class="container">
                    <div class="tab-content pt-4">
                        <div v-for="(post, index) in baseData.survei" :key="index">
                            <div class="card-grid">
                                <div class="card" v-for="(layanan, index) in post?.data" :key="index">
                                    <img v-lazy="fstring.getAssetpath(layanan?.icon)" class="img-fluid"
                                        alt="baketrans" />
                                    <p class="card-subtitle">{{ layanan?.subtitle }}</p>
                                    <a :href="layanan?.link" :target="layanan?.target" rel="noopener noreferrer"
                                        class="card-button" aria-label="baketrans"><i class="bi bi-link-45deg"></i>
                                        Isi Survei</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppBreadCrumb from '@/views/layouts/bread-crumb.vue'
import { mapState, mapActions } from 'vuex'
import { fstring } from '@/lib/fstring.js'

export default {
    components: {
        AppBreadCrumb
    },
    data() {
        return {
            fstring: fstring,
            isloading: false,
            placeholderImage: '@/assets/img/icons/image-load-failed.png',
            data: {
                name: "/",
                title: "Survei Kepuasan Masyarakat",
                description: "",
                link: [
                    {
                        title: "Survei Kepuasan Masyarakat",
                        path: "/survei-kepuasan-masyarakat",
                        description: ""
                    }
                ]
            }
        };
    },
    watch: {
        async $route(to, from) {
            this.isloading = true
            try {
                await this.getSurvei();
            } finally {
                this.isloading = false;
            }
        }
    },
    computed: {
        ...mapState('layananPage', {
            baseData: (state) => state.survei
        })
    },
    async mounted() {
        this.isloading = true
        try {
            await this.getSurvei();
        } finally {
            this.isloading = false;
        }
    },
    methods: {
        ...mapActions('layananPage', ['getSurvei'])
    }
};
</script>

<style scoped>
.direktori-layanan {

    margin-bottom: 100px;


    .card-grid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 30px;

    }

    .card {

        /* Fixed width */
        min-height: 400px;
        max-height: 460px;
        /* Fixed height */
        padding: 20px;
        border-radius: 1.25rem;
        /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
        background-color: #fff;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: relative;
        /* Make the card a positioning context */
        overflow: hidden;
        /* To ensure content doesn't overflow */
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        border: 1px solid #f3f3f4;
    }

    .card:hover {
        transform: translateY(-10px);
        /* Lift the card up */
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
        /* Enhance shadow on hover */
    }

    .card-title {
        font-size: 1.125rem;
        line-height: 1.75rem;
        margin: 0 0 20px 0;
        text-transform: uppercase;
        /* Increased bottom margin to add more space */
    }



    .card-subtitle {

        color: #111827;

        font-weight: 500;
        font-size: .675rem;
        line-height: 1.25rem;
    }


    .card-button {
        padding: 10px 20px;
        border: none;
        border-radius: 25px;
        background-color: #001165;
        color: #ffff;
        font-size: 13px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        text-decoration: none;
        /* Ensure the link looks like a button */
        display: inline-block;
        /* Ensure proper button styling */
    }

    .card-button:hover {
        background-color: #ffc41d;
        color: #fff;
    }

    @media (max-width: 1200px) {
        .card-grid {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media (max-width: 768px) {
        .card-grid {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (max-width: 480px) {
        .card-grid {
            grid-template-columns: 1fr;
        }
    }
}
</style>