<template>
    <section class="kalender-section">
        <div class="container pt-4">
            <h2 class="section-title pt-4">
                {{ (lang == 'en' ? 'Calendar Baketrans' : 'Kalender Baketrans') }}
                <p
                    v-html="(lang == 'en' ? 'This calendar contains events that will be held by Baketrans' : 'Kalender ini berisi acara-acara yang akan diadakan oleh Baketrans')">
                </p>
            </h2>

            <div class="calendar-container1 row justify-content-md-center">
                <div class="col-md-3">
                    <div :class="{ 'disabled-div': disabledDates }">
                        <v-date-picker v-model="dateRange" is-range expanded :attributes="calendarAttributes"
                            class="custom-event-calendar" :hide-header="false" @update:pages="onMonthChange" is24hr
                            :timezone="`Asia/Jakarta`" />
                    </div>
                </div>

                <div class="col-md-5">
                    <div v-if="selectedEvent" class="event-details-view">
                        <div class="event-details-text">
                            <h2 class="event-details-title">{{ selectedEvent?.title ?? '-' }}</h2>
                            <p v-if="selectedEvent?.description" class="event-details-description text-black p-2">{{
                    selectedEvent?.description ?? '-' }}
                            </p>
                            <span class="event-details-date">
                                <ul class="p-2">
                                    <li v-if="selectedEvent?.trainer">
                                        <i class="bi bi-person-workspace me-2"></i>{{ selectedEvent?.trainer ?? '-' }}
                                    </li>
                                    <li v-if="selectedEvent?.location">
                                        <i class="bi bi-geo-alt-fill me-2"></i>{{ selectedEvent?.location ?? '-' }}
                                    </li>
                                    <li v-if="selectedEvent?.location_coordinates"><i class="bi bi-map me-2"></i>
                                        <a :href="selectedEvent?.location_coordinates" target="_blank"
                                            aria-label="location">
                                            {{ selectedEvent?.location_coordinates ?? '-' }}
                                            <i class="bi bi-box-arrow-up-right ms-2"></i>
                                        </a>
                                    </li>
                                    <li v-if="selectedEvent?.date_start">
                                        <i class="bi bi-calendar-date me-2"></i>
                                        {{
                    (selectedEvent?.date_start ==
                        selectedEvent?.date_end ?
                        fstring.formattedDate(selectedEvent?.date_start) :
                        fstring.formattedDate(selectedEvent?.date_start) + ' - ' +
                        fstring.formattedDate(selectedEvent?.date_end))
                }}
                                    </li>
                                    <li>
                                        <i class="bi bi-alarm me-2"></i>{{ selectedEvent?.time_start ?? '?' }} - {{
                    selectedEvent?.time_end ?? '?' }}
                                    </li>
                                </ul>
                            </span>
                        </div>
                    </div>
                    <div v-else class="no-event-selected">
                        <p>Select an event to see details</p>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="event-list">
                        <ul>
                            <li v-for="event in filterData" :key="event.id" class="event-item"
                                @click="selectEvent(event)">
                                <img v-lazy="fstring.getAssetpath(event?.thumbnail)" alt="baketrans" />
                                <div class="event-details">
                                    <h4 class="event-title">{{ event?.title ?? '-' }}</h4>
                                    <p class="fs-11"> {{ (event?.date_start == event?.date_end ?
                    fstring.formattedDate(event?.date_start) :
                    fstring.formattedDate(event?.date_start) + ' - ' +
                                        fstring.formattedDate(event?.date_end))
                                        }}
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import moment from 'moment';
import { fstring } from '@/lib/fstring.js'
import { mapState, mapActions } from 'vuex'
import 'v-calendar/dist/style.css';

export default {
    data() {
        return {
            fstring: fstring,
            lang: fstring.getLang(),
            calendarAttributes: [],
            selectedEvent: null,
            selectedMonthYear: null,
            disabledDates: false,

            customTheme: {
                backgroundColor: '#fff',
                borderColor: '#ddd',
                borderRadius: '0',
                padding: '0.7rem',
                hoverBackgroundColor: '#eee',
                selectedColor: '#001165'
            },
            dateRange: null,

        };
    },
    watch: {
        async dateRange(newVal, oldVal) {
            if (newVal != oldVal) {
                await this.getKegiatanList([moment(newVal.start).format('YYYY-MM-DD'), moment(newVal.end).format('YYYY-MM-DD')]);
            }

        }
    },
    computed: {
        ...mapState('kegiatanPage', {
            baseData: (state) => state.kegiatan_list,
            baseDataMark: (state) => state.kegiatan_mark
        }),

        filterData() {
            this.selectedEvent = this.baseData[0]
            return this.baseData;

        }
    },
    async mounted() {



    },
    methods: {
        ...mapActions('kegiatanPage', ['getKegiatanList', 'getKegiatanMark']),
        async onMonthChange(date) {
            if (this.disabledDates == date) {
                return false;
            }
            this.disabledDates = true;
            if (date) {
                if (date != this.selectedMonthYear) {
                    this.selectedMonthYear = date;
                    await this.getKegiatanMark([date[0].year, date[0].month]);
                    const markData = this.baseDataMark;
                    this.calendarAttributes = [];
                    if (markData) {
                        this.calendarAttributes = markData.map(event => (

                            {
                                popover: {
                                    label: event.title,
                                    visibility: 'hover',
                                    hideIndicator: true,
                                    isComplete: true,
                                    color: 'red',
                                },

                                color: 'red',
                                highlight: {
                                    color: 'red',
                                    fillMode: 'solid',
                                },
                                // dot: {
                                //     color: 'gray',
                                //     class: 'opacity-50',
                                // },
                                dates: [new Date(event.date_start)]

                            }
                            // {
                            //     highlight: {
                            //         style: {
                            //             backgroundColor: '#ebC758',
                            //             borderColor: '#001165',
                            //         },
                            //     },
                            //     dates: [new Date(event.date_start)]
                            // }


                        ));

                        const startDate = new Date(date[0].year, date[0].month - 1, 2);
                        const endDate = new Date(date[0].year, date[0].month, 1);
                        await this.getKegiatanList([startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]]);
                    }
                }
            }
            this.disabledDates = false;
        },

        selectEvent(event) {
            this.selectedEvent = event;
        }
    },

};
</script>


<style scoped>
.kalender-section {
    padding: 20px;
    background-color: #E4FBFF;
    margin-bottom: 85px;
    margin-top: 10px;
}

.section-title {
    font-size: 28px;
    font-weight: bold;
    color: #333;
    margin-bottom: 50px;
    text-align: center;
}

.section-title p {
    margin: 0;
    padding: 0;
    font-size: 12px;
}

.calendar-container {
    display: flex;
    justify-content: space-between;
}

.custom-event-calendar .v-calendar__day {
    font-size: 10px;
    /* Day font size */
}

.event-list {
    margin-top: 5px;
    max-height: 250px;
    overflow-y: auto;
    background-color: transparent;
    box-shadow: none;
    padding: 4px;
    flex-grow: 1;
    border-radius: 10px;
    color: red;
    /* border: 1px solid #cbd5e1 */
}

.event-list::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}

.event-list::-webkit-scrollbar-thumb {
    background-color: #cdcdcd;
}

.event-list::-webkit-scrollbar-track {
    background-color: #e4e4e4;
}

.event-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* Align items to fill the space horizontally */
    padding: 5px;
    margin-bottom: 5px;
    border: 1px solid #f3f3f4;
    background-color: #CAF0F8;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.event-item img {
    width: 75px;
    height: 50px;
    margin-right: 10px;
    object-fit: cover;
    flex-shrink: 0;
    padding: 2px;
    border-radius: 10px;
}

.event-details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    /* Title and description stacked */
    overflow: hidden;
    /* Ensure text does not overflow */
}

.event-title {
    font-size: 12px;
    font-weight: 500;
    color: #023e8a;
}

.event-description {
    font-size: 10px;
    color: #666;
    margin-top: 4px;
    /* Small space between title and description */
    line-height: 1.4;
    overflow: hidden;
    /* Ensure text does not overflow */
    text-overflow: ellipsis;
    /* Add ellipsis for overflowing text */
    white-space: normal;
    -webkit-line-clamp: 4 !important;
    display: -webkit-box;

    -webkit-box-orient: vertical;
}

.event-date {
    font-size: 10px;
    color: #424242;
    margin-left: 10px;
    /* Separate the date from the text */
    white-space: nowrap;
    /* Prevents text wrapping for the date */
}

.event-item .right-section {
    display: flex;
    align-items: center;
    /* Aligns the date in the center vertically */
}

.event-item:hover {
    background-color: #f8f8f8;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-color: #ccc;
}

.event-details-side {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 20px;
    flex-grow: 1;
}

.event-details-view {
    background-color: #E4FBFF;
    margin-top: 8px;
    color: red;
}

.event-details-view img {
    width: 100%;
    max-height: 250px;
    object-fit: cover;
    margin-bottom: 20px;
    border-radius: 20px 20px 0 0;
    /* border: 5px solid #f5f5f5; */
}

.event-details-text {
    flex-grow: 1;
    padding: 4px;
}

.event-details-title {
    font-size: 13px;
    font-weight: 600;
    color: #000;
    padding: 10px;
    background-color: #CAF0F8;
    border-radius: 1rem;
}

.event-details-description {
    font-size: 12px;
    color: #000;
    margin-top: 10px;
    line-height: 1.6;
}

.event-details-date {
    font-size: 12px;
    color: #000;
    margin-top: 10px;
}

.no-event-selected {
    text-align: center;
    color: #888;
    padding: 20px;
    flex-grow: 1;
}

.no-event-selected p {
    font-size: 18px;
}

.disabled-div {
    pointer-events: none;
    opacity: 0.5;
    background-color: #f0f0f0;
    cursor: not-allowed
}
</style>
