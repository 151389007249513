<template>
  <div class="main-wrapper">
    <div class="page-wrapper">
      <app-bread-crumb :homeText="lang == 'en' ? 'Home ' : ' Beranda'" :path="data.link" :homeRoute="data.name"
        :title="data.title" :description="data.description" />

      <div class="bloglisting">
        <div class="container">
          <template v-if="options.sViewMode == 'list'">
            <viewList v-if="options.sViewMode == 'list'" :baseData="baseData" :baseDataKategori="baseDataKategori"
              :totalRecord="totalRecord" @showImage="showImage" @checkEnter="checkEnter" @searchData="searchData"
              @changeCategories="changeCategories" :options="options" />
          </template>

          <template v-if="options.sViewMode == 'grid'">
            <viewGrid v-if="options.sViewMode == 'grid'" :baseData="baseData" :baseDataKategori="baseDataKategori"
              :totalRecord="totalRecord" @showImage="showImage" @checkEnter="checkEnter" @searchData="searchData"
              @changeCategories="changeCategories" :options="options" />
          </template>

          <div :class="(options.sViewMode == 'grid' ? 'blog-pagination col-lg-12' : 'blog-pagination col-lg-9')"
            v-if="totalRecord > 0">
            <nav v-show="!options.isloading">
              <ul class="pagination justify-content-center pt-4">
                <li class="page-item previtem" :class="{ disabled: options.currentPage === 1 }">
                  <a class="page-link" href="#" @click.prevent="prevPage" aria-label="Previous">
                    <i class="fas fa-regular fa-arrow-left"></i>
                  </a>
                </li>
                <li class="justify-content-center pagination-center">
                  <div class="pagelink">
                    <ul>
                      <li class="page-item" v-for="page in visiblePages" :key="page"
                        :class="{ active: options.currentPage === page, 'page-ellipsis': page === '...' }">
                        <a class="page-link" href="#" @click.prevent="goToPage(page)" v-if="page !== '...'"
                          aria-label="Next">{{
        page
      }}</a>
                        <span v-else class="page-link">{{ page }}</span>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="page-item nextlink" :class="{ disabled: options.currentPage === totalPages }">
                  <a class="page-link" href="#" @click.prevent="nextPage" aria-label="Next">
                    <i class="fas fa-regular fa-arrow-right"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadCrumb from '@/views/layouts/bread-crumb.vue'
import viewList from './berita-mode-list.vue'
import viewGrid from './berita-mode-grid.vue'
import Swal from 'sweetalert2'
import { mapState, mapActions, useStore } from 'vuex'
import { fstring } from '@/lib/fstring.js'
// import SkeletonBox from '@/component/SkeletonBox.vue'

export default {
  components: {
    AppBreadCrumb,
    viewList,
    viewGrid,
    // SkeletonBox
  },
  data() {
    return {
      fstring: fstring,
      lang: fstring.getLang(),
      options: {
        isloading: false,
        sQuery: '',
        sQuerySubmit: '',
        sCategory: '',
        sViewMode: '',
        placeholderImage: '@/assets/img/icons/image-load-failed.png',
        currentPage: 1,
        sTitle: ''
      },
      data: {
        name: "/",
        title: "",
        description: "",
        fstring: fstring,
        link: [
          {
            title: (fstring.getLang() == 'en' ? 'Publication' : 'Publikasi'),
            path: "/berita",
            description: ""
          },
          {
            title: "",
            path: "/berita",
            description: ""
          }
        ]
      }
    };
  },
  watch: {
    async $route(to, from) {
      this.options.isloading = true
      try {
        this.options.currentPage = 1;
        this.options.sCategory = (to.params.id == undefined ? '' : to.params.id);
        if (this.options.sCategory == undefined || this.options.sCategory == null || this.options.sCategory == '') {
          await this.getNews([1, "", ""])
        } else {
          await this.getNews([1, this.options.sQuerySubmit, this.options.sCategory]);
        }
        this.options.sViewMode = this.baseData.categories.mode;
        this.data.link[1].title = this.baseData.categories.title;
        this.data.title = this.baseData.categories.title;
        this.options.sTitle = this.baseData.categories.title;
        await this.getNewsCategory(["berita"])

      } catch (e) {
        this.$router.push({ name: 'error-404' });
      } finally {

        this.options.isloading = false;
      }
    }
  },
  computed: {
    ...mapState('beritaPage', {
      baseData: (state) => state.berita
    }),
    ...mapState('kategoriPage', {
      baseDataKategori: (state) => state.kategori
    }),
    // Total pages based on filtered results
    totalPages() {
      return this.baseData.news.last_page;
    },
    itemsPerPage() {
      return this.baseData.news.per_page;
    },
    totalRecord() {
      let total = 0;
      if (this.baseData.news) {
        total = this.baseData.news.total;
      }
      return total;
    },


    // Determine visible pages with ellipses
    visiblePages() {
      const pages = [];
      const maxPagesToShow = 8;//this.itemsPerPage;
      const currentPage = this.baseData.news.current_page;
      const totalPages = this.totalPages;

      if (totalPages <= maxPagesToShow) {
        for (let i = 1; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        let startPage = Math.max(currentPage - 1, 1);
        let endPage = Math.min(currentPage + 1, totalPages);

        if (currentPage <= 2) {
          endPage = maxPagesToShow;
        } else if (currentPage >= totalPages - 1) {
          startPage = totalPages - (maxPagesToShow - 1);
        }

        for (let i = startPage; i <= endPage; i++) {
          pages.push(i);
        }

        if (startPage > 1) {
          pages.unshift('...');
          pages.unshift(1);
        }

        if (endPage < totalPages) {
          pages.push('...');
          pages.push(totalPages);
        }
      }

      return pages;
    }
  },
  async mounted() {
    this.options.isloading = true
    try {
      this.options.sCategory = this.$route.params.id;
      if (this.options.sCategory == undefined || this.options.sCategory == null || this.options.sCategory == '') {
        await this.getNews([1, "", ""])
      } else {
        await this.getNews([, "", this.options.sCategory])
      }
      this.options.sViewMode = this.baseData.categories.mode;
      this.data.link[1].title = this.baseData.categories.title;
      this.options.sTitle = this.baseData.categories.title;
      this.data.title = this.baseData.categories.title;
      await this.getNewsCategory(["berita"])
    } catch (e) {
      this.$router.push({ name: 'error-404' });
    } finally {
      this.options.isloading = false;
    }
  },
  methods: {
    ...mapActions('beritaPage', ['getNews']),
    ...mapActions('kategoriPage', ['getNewsCategory']),
    checkEnter(event) {
      if (event.key === 'Enter') { // Check if the key is Enter
        this.searchData();
      }
    },
    async searchData() {
      try {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.options.sCategory = (this.options.sCategory == undefined ? '' : this.options.sCategory);
        this.options.sQuerySubmit = this.options.sQuery;
        await this.getNews([1, this.options.sQuerySubmit, this.options.sCategory]);
      } finally {
        this.options.currentPage = 1;

      }
    },
    async changeCategories(value, index) {
      this.$router.push('/berita/kategori/' + value.slug);
    },
    showImage(url) {
      if (url == null) {
        url = this.options.placeholderImage;
      }
      this.$startLoading();
      Swal.fire({
        html: `
        <div v-lazy-container="{ selector: 'img', error: '/assets/images/icons/image-load-failed.png', loading: '/assets/images/icons/image-load.gif' }">
          <img class="img-fluid" src="${url}">
        </div>
        `,
        showConfirmButton: false,
        showCloseButton: true,
        //timer: 2500,
        width: 854,
        heightAuto: false,

        customClass: {
          confirmButton: "btn btn-light-success btn-sm",
          cancelButton: "btn btn-light-danger btn-sm"
        },
        buttonsStyling: false,
        didOpen: () => {
          this.$completeLoading();
        }
      });
    },
    async goToPage(page) {
      if (page !== '...' && page > 0 && page <= this.totalPages) {
        try {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          await this.getNews([page, this.options.sQuerySubmit, this.options.sCategory])
        } finally {
          this.options.currentPage = page;
        }
      }
    },
    async nextPage() {
      if (this.options.currentPage < this.totalPages) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.options.currentPage++;
        await this.getNews([this.options.currentPage, this.options.sQuerySubmit, this.options.sCategory])
      }
    },
    async prevPage() {
      if (this.options.currentPage > 1) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.options.currentPage--;
        await this.getNews([this.options.currentPage, this.options.sQuerySubmit, this.options.sCategory])
      }
    },
  }
};
</script>

<style scoped>
.pagination .pagination-center {
  width: auto;
}
</style>